`
<template>
    <div class="mc">
        <HeaderMenu/>
        <section class="sectionCart">
            <div class="container">
                <div class="content">
                    <transition-group name="transX">
                        <div class="shop--item" v-for="speck in this.specks" :key="speck.speckID">
                            <img class="shop--item--img"
                                 :src="require(`@/assets/images/New_Images/speckImage/lowerSize/${speck.speckImage}`)"
                                 alt=""
                                 loading="lazy"/>
                            <div class="shop--item--description">
                                <h3>{{ speck.className }}</h3>
                                <p :style="{'color':speck.color}" class="shop--item--name">{{ speck.speckName }}</p>
                            </div>
                            <button class="shop--item--delete" @click="removeSpeck(speck)"><img
                                    src="@/assets/images/New_Images/cross.png" alt=""></button>
                            <p class="shop--item--cost">Цена: {{ cost }}р.</p>
                        </div>
                    </transition-group>
                    <transition name="totalSum">
                        <div v-if="specks.length" class="cart--price">
                            <div class="cart--price--box">
                                <div class="cart--price--information">
                                    <div>
                                        <p>Количество товаров: {{ specks.length }} шт.</p>
                                        <p>Тариф: {{ totalTariff }}</p>
                                    </div>
                                    <button class="">
                                        <img
                                                src="@/assets/images/New_Images/cross.png"
                                                @click="removeAllSpecks" alt="">
                                    </button>
                                </div>
                                <div class="cart--tariff--img">
                                    <img v-if="countStartProduct" src="@/assets/images/New_Images/start.png"
                                         alt=""/>
                                    <img v-if="countStandardProduct" src="@/assets/images/New_Images/medium.png"
                                         alt=""/>
                                    <img v-if="countVIPProduct" src="@/assets/images/New_Images/vip.png" alt=""/>
                                </div>
                                <div class="cart--ID">ID Корзины:
                                    <span class="ID" @click="copyToTheBuffer()"
                                            :class="showNotify ? 'colorAnimation' : ''">{{ this.cartId }}
                                        <span class="tooltiptext">Нажми чтобы скопировать</span>
                                    </span>
                                </div>
                                <div class="cart--mail">
                                    <input class="cart--mail--input" :class="[emailIsValidValue? 'goodEmail' : '']"
                                           type="email" placeholder="" @keyup="validateEmail"
                                           v-model="email">
                                    <label>YourMail@somewhere.com</label>
                                    <span class="focus-border">
                                        <i></i>
                                    </span>
                                </div>

                                <div class="cart--buy--box">
                                    <div class="cart--cost">
                                        <p v-show="countStandardProduct || countVIPProduct" class="cart--cost--full">Цена
                                            без
                                            скидки:
                                            {{ specks.length * cost }}р.</p>
                                        <p class="cart--cost--sale">Итоговая цена:
                                            {{ totalCost }}р.</p>
                                    </div>
                                    <button class="cart--get--pay"
                                            :class="[emailIsValidValue ? 'can--pay' : '']"
                                            @click="pay" :disabled="!emailIsValidValue">Оплатить
                                    </button>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
                <transition name="fade">
                    <div v-if="!specks.length" class="empty-cart">
                        <img src="@/assets/images/New_Images/Logo.png" alt=""/>

                    </div>
                </transition>
            </div>
        </section>
        <UpArrow/>
        <Footer/>
    </div>

</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import axios from "axios";
import HeaderMenu from "@/components/HeaderMenu";
import UpArrow from "@/components/UpArrow";
import Footer from "@/components/Footer";

export default {
    name: "Cart",
    components: {Footer, UpArrow, HeaderMenu},
    data() {
        return {
            countStartProduct: 0,
            countStandardProduct: 0,
            countVIPProduct: 0,
            email: '',
            reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            emailCorrectFlag: false,
            serverIsWork: false,
            cartId: "",
            showNotify: false,
        }
    },
    computed: {
        ...mapGetters({
            specks: 'getSelectedSpecks',
            cost: 'getCostBeginner',
            costStandard: 'getCostStandard',
            costVIP: 'getCostVIP',
        }),
        totalTariff: function () {
            let context = this;
            context.countVIPProduct = 0;
            context.countStandardProduct = 0;
            context.countStartProduct = 0;
            let countProductInCart = this.specks.length;
            let multipleStandard = 0;
            if (countProductInCart === 29) {
                context.countVIPProduct = 1;
                return 'VIP';
            }
            for (let i = 5; i >= 3; i--) {
                if (countProductInCart >= i) {
                    multipleStandard += Math.trunc(countProductInCart / i);
                    countProductInCart -= Math.trunc(countProductInCart / i) * i;
                }
            }
            let result = '';
            if (countProductInCart > 0) {
                result += countProductInCart + 'х Старт ';
                context.countStartProduct = countProductInCart;
            }
            if (multipleStandard > 0) {
                result += multipleStandard + 'х Стандарт';
                context.countStandardProduct = multipleStandard;
            }
            if (result === '')
                result = '-';
            return result;
        },
        totalCost: function () {
            if (this.countVIPProduct) {
                return this.costVIP;
            }
            return Math.floor(this.cost * this.countStartProduct + this.costStandard * (this.specks.length - this.countStartProduct));
        },
        emailIsValidValue() {
            this.validateEmail()
            return this.emailCorrectFlag
        }

    },
    methods: {
        ...mapMutations([
            "removeSpeck",
            "removeAllSpecks",
            "loadOldSession",
            "openUrl"
        ]),
        pay() {
            this.serverIsWork = false;
            let data = JSON.stringify({
                specks: this.getSpecksID(this.specks),
                email: this.email,
                cartId: this.cartId,
            })
            const url = '/api/pay';
            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                    .then((response) => {
                                sessionStorage.setItem('PaymentId', response.data.id);
                                this.serverIsWork = true;
                                window.location.href = response.data.redirect_url;
                            },
                            (error) => {
                                window.alert("Сервер не отвечает!\nПопробуйте позже.")
                                this.serverIsWork = true;
                                console.log(error)
                            });
        },
        getCartId() {
            const url = '/api/pay/getCartId';
            axios.get(url)
                    .then((response) => {
                                this.cartId = response.data.toString()
                            },
                            (error) => {
                                console.log(error)
                            })
        },
        getSpecksID(specks) {
            let speck;
            let specksID = [];
            for (speck of specks) {
                specksID.push(speck.speckID)
            }
            return specksID
        },
        validateEmail() {
            if (this.email === "" || !this.reg.test(this.email)) {
                this.emailCorrectFlag = false;
            } else {
                this.emailCorrectFlag = true;
            }
        },
        copyToTheBuffer() {
            navigator.clipboard.writeText(this.cartId).then(() => {
                if (this.showNotify === false) {
                    this.showNotify = true;
                    setTimeout(() => this.showNotify = false, 400);
                }
            })
        },
    },
    created() {
        this.getCartId()
    },
}
</script>

<style lang="scss" scoped>
.mc{
}
.totalSum-leave-active {
    transition: all .3s ease;
}

.totalSum-leave-to {
    transform: translateX(25px);
    opacity: 0;
}

.transX-leave-active {
    transition: all .4s ease;
}

.transX-leave-to {
    transform: translateX(-25px);
    opacity: 0;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 0.8s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}

.sectionCart {
    position: relative;
    min-height: 800px;
    flex: 1;
    background-color: var(--main-bg-color);
    cursor: default;

    .content {
        margin-top: 90px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        column-gap: 50px;

        span {
            flex: 1;
        }

        .shop--item {
            display: flex;
            flex-direction: row;
            margin-top: 30px;
            background-color: var(--dark-bg-color);
            min-width: 530px;
            position: relative;

            &--description {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-top: 5px;

                .shop--item--name {
                    margin-top: 90px;
                    font-size: 18px;
                    font-weight: bolder;
                    text-transform: uppercase;
                }
            }

            &--delete {
                position: absolute;
                top: 10px;
                right: 10px;
                transition: filter .3s ease-out;
                filter: invert(25%) sepia(78%) saturate(2544%) hue-rotate(41deg) brightness(93%) contrast(96%);

                &:hover {
                    filter: invert(62%) sepia(98%) saturate(3198%) hue-rotate(14deg) brightness(101%) contrast(99%);
                }
            }

            &--cost {
                position: absolute;
                bottom: 10px;
                right: 10px;
            }


            h3 {
                color: var(--orange-color);
                font-family: var(--title-font);
                font-size: 30px;
            }

            p {
                color: var(--orange-color);
                font-family: var(--regular-font);
                font-size: 14px;
            }
        }

        .cart--price {
            flex: 0.5;

            &--box {
                margin-top: 30px;
                background-color: var(--dark-bg-color);
                display: flex;
                flex-direction: column;
                padding: 10px;
                position: sticky;
                top: 70px;
            }

            &--information {
                font-family: var(--regular-font);
                font-size: 14px;
                font-weight: bolder;
                color: var(--blue-color);
                display: flex;
                flex-direction: row;
                gap: 20px;
                justify-content: space-between;

                button {
                    transition: all 0.3s ease-out;
                    filter: invert(17%) sepia(67%) saturate(3970%) hue-rotate(172deg) brightness(97%) contrast(97%);

                    &:hover {
                        filter: invert(60%) sepia(46%) saturate(3361%) hue-rotate(143deg) brightness(87%) contrast(95%);
                    }
                }
            }

            .cart--tariff--img {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                img {
                    max-width: 200px;
                }
            }

            .cart--ID {
                margin-top: 10px;
                color: var(--white-color);
                font-family: var(--regular-font);
                position: relative;
                .ID {
                    color: var(--orange-color);
                    cursor: pointer;
                    .tooltiptext {
                        visibility: hidden;
                        width: 120px;
                        background-color: var(--orange-color);
                        color: var(--dark-bg-color);
                        text-align: center;
                        padding: 5px 0;
                        border-radius: 6px;
                        position: absolute;
                        z-index: 1;
                        bottom: 100%;
                        left: 50%;
                        margin-left: -60px;
                        font-size: 11px;

                        &::after {
                            content: " ";
                            position: absolute;
                            top: 100%; /* At the bottom of the tooltip */
                            left: 50%;
                            margin-left: -5px;
                            border-width: 5px;
                            border-style: solid;
                            border-color: var(--orange-color) transparent transparent transparent;
                        }
                    }

                    &:hover .tooltiptext {
                        visibility: visible;
                        animation: openTooltip 0.4s ease forwards;
                        @keyframes openTooltip {
                            0% {
                                opacity: 0;
                            }
                            100%{
                                opacity: 1;
                            }
                        }
                    }
                }
                .colorAnimation{
                    animation: colorChange .4s ease;
                    @keyframes colorChange {
                        0%{
                            color: var(--orange-color);
                        }
                        50%{
                            color: var(--blue-color);
                        }
                        100%{
                            color: var(--orange-color);
                        }
                    }
                }
            }

            .cart--mail {
                position: relative;
                font-family: var(--regular-font);
                margin-top: 30px;

                .cart--mail--input {
                    border: 1px solid rgb(32, 42, 46);
                    padding: 7px 14px;
                    transition: 0.4s;
                    background: var(--dark-bg-color);
                    width: 100%;
                    position: relative;
                    z-index: 2;
                    color: var(--white-color);
                    font-size: 12px;

                    &:placeholder-shown {
                        background-color: transparent;
                    }

                    &.goodEmail {
                        border-color: #50eb6f;
                        z-index: 3;
                    }
                }

                .cart--mail--input ~ .focus-border:before,
                .cart--mail--input ~ .focus-border:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 0;
                    height: 2px;
                    background-color: var(--orange-color);
                    transition: 0.3s;
                    z-index: 2;
                }

                .cart--mail--input ~ .focus-border:after {
                    top: auto;
                    bottom: 0;
                    left: auto;
                    right: 0;
                }

                .cart--mail--input ~ .focus-border i:before,
                .cart--mail--input ~ .focus-border i:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 2px;
                    height: 0;
                    background-color: var(--orange-color);
                    transition: 0.4s;
                    z-index: 2;
                }

                .cart--mail--input ~ .focus-border i:after {
                    left: auto;
                    right: 0;
                    top: auto;
                    bottom: 0;
                }

                .cart--mail--input:focus ~ .focus-border:before,
                .cart--mail--input:focus ~ .focus-border:after,
                .has-content.cart--mail--input ~ .focus-border:before,
                .has-content.cart--mail--input ~ .focus-border:after {
                    width: 100%;
                    transition: 0.3s;
                }

                .cart--mail--input:focus ~ .focus-border i:before,
                .cart--mail--input:focus ~ .focus-border i:after,
                .has-content.cart--mail--input ~ .focus-border i:before,
                .has-content.cart--mail--input ~ .focus-border i:after {
                    height: 100%;
                    transition: 0.4s;
                }

                .cart--mail--input ~ label {
                    position: absolute;
                    left: 6px;
                    width: 100%;
                    top: 5px;
                    color: #484653;
                    transition: 0.3s;
                    z-index: 1;
                    letter-spacing: 0.5px;
                }

                .cart--mail--input:focus ~ label, .has-content.cart--mail--input ~ label {
                    top: -18px;
                    left: 0;
                    font-size: 12px;
                    color: var(--orange-color);
                    transition: 0.3s;
                }
            }

            .cart--buy--box {
                margin-top: 15px;
                display: flex;
                gap: 50px;
                justify-content: space-between;
                min-height: 36px;

                .cart--cost {
                    p {
                        font-family: var(--regular-font);
                        color: var(--white-color);
                        font-size: 12px;
                    }

                    p:nth-child(1) {
                        text-decoration: line-through;
                    }

                    p:nth-child(2) {
                        font-size: 15px;
                        color: var(--blue-light-color);
                    }
                }

                .cart--get--pay {
                    background-color: var(--grey-light-color);
                    padding: 0px 30px;
                    font-family: var(--regular-font);
                    font-size: 14px;
                    border: 1px solid transparent;
                    color: var(--dark-bg-color);
                    transition: all .3s ease-out;
                }
                .can--pay{
                    position: relative;
                    z-index: 1;
                    background-color: transparent;
                    animation: openBtnPay 0.4s forwards;
                    @keyframes openBtnPay {
                        0% {
                            opacity: 0;
                        }
                        100%{
                            opacity: 1;
                        }
                    }
                    &::before {
                        background: linear-gradient(var(--orange-color), var(--orange-dark-color));
                        position: absolute;
                        content: '';
                        height: 100%;
                        width: 100%;
                        top: 0;
                        left: 0;
                        z-index: -1;
                        transition: all 0.3s ease-in-out;
                    }
                    &::after {
                        position: absolute;
                        border: 2px solid var(--orange-color);
                        content: '';
                        height: 100%;
                        width: 100%;
                        top: 0;
                        left: 0;
                        z-index: -1;
                        opacity: 0;
                        transform: scale(1.2, 1.2);
                        transition: all 0.3s ease-in-out;
                    }
                    &:hover {
                        color: var(--orange-color);

                        &::before {
                            opacity: 0;
                        }

                        &::after {
                            opacity: 1;
                            transform: scale(1, 1);
                        }
                    }
                }
            }
        }
    }

    .empty-cart{
        opacity: .2;
        filter: grayscale(0.2) blur(5px);
        justify-content: center;
        display: flex;
    }

    @media (max-width: 1050px) {
        .content{
            column-gap: 10px;
            flex-direction: column-reverse;
            padding-bottom: 20px;
        }
        .content .shop--item h3{
            margin-top: 10px;
            font-size: 20px;
        }
        .content .shop--item--description .shop--item--name{
            font-size: 14px;
        }
    }

    @media (max-width: 570px) {
        .content{
            gap: 20px;
            .shop--item{
                margin-top: 10px;
                min-width: 100%;
                h3{
                    margin-top: 60px;
                }
            }
            .shop--item--description .shop--item--name{
                margin-top: 50px;
            }
            .cart--price .cart--tariff--img img{
                max-width: 170px;
            }
        }
    }

    @media (max-width: 420px) {
        .content{
            gap: 20px;
            .shop--item{
                img{
                    width: 30%;
                }
                h3{
                    margin-top: 30px;
                }
            }
            .shop--item--description .shop--item--name{
                margin-top: 10px;
            }

            .cart--price--information{
                font-size: 12px;
            }
            .cart--price .cart--ID{
                font-size: 12px;
            }
            .cart--price .cart--mail{
                margin-top: 15px;
            }
            .cart--price .cart--tariff--img img{
                max-width: 120px;
            }
        }
    }

}


</style>