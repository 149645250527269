<template>
    <section class="unstoppable">
        <div class="container">
            <div class="content">
                <div class="unstoppable--title">
                    <h2 class="you">Тебя <span>не</span></h2>
                    <h2 class="stop">Оста<span class="no">но</span>вить</h2>
                </div>
                <div class="prefs">
                    <div class="prefs--item">
                        <h2>01</h2>
                        <p>Мы ценим игроков которые готовы бросить вызов испытаниям</p>
                    </div>
                    <div class="prefs--item">
                        <h2>02</h2>
                        <p>Твоя гильдия всегда будет ждать тебя на РТ</p>
                    </div>
                    <div class="prefs--item">
                        <h2>03</h2>
                        <p>миллион новых ощущений</p>
                    </div>
                    <div class="prefs--item">
                        <h2>04</h2>
                        <p>это и многое другое по доступной цене</p>
                    </div>
                </div>
                <div class="download--frame">
                    <div class="download--frame--button" @click="downloadApp"><span>Скачать</span></div>
                </div>
                <div class="image"><img src="@/assets/images/New_Images/Horse.png" alt=""></div>
            </div>
        </div>
    </section>
</template>

<script>
import {mapMutations} from "vuex";

export default {
    name: "Unstoppable",
    methods: {
        ...mapMutations({
            downloadApp: "downloadApp",
        })
    }
}
</script>

<style lang="scss">
.unstoppable {
    background-color: var(--main-bg-color);

    .content {
        min-height: 700px;
        background-color: var(--orange-color);
        display: flex;
        flex-direction: column;
        overflow: hidden;
        position: relative;
        z-index: 1;

        .unstoppable--title {
            margin-top: 120px;
            margin-left: 215px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            h2 {
                font-family: var(--title-font);
                font-size: 48px;
                display: inline;
                width: initial;
            }

            .you {
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    height: 4px;
                    width: 1000px;
                    background-color: var(--main-bg-color);
                }
            }

            .stop {
                margin-left: 130px;
                color: var(--blue-color);

                .no {
                    color: var(--orange-color);
                    position: relative;
                    z-index: 1;

                    &::before {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 1000px;
                        background-color: var(--main-bg-color);
                        z-index: -1;
                        top: -200px;
                    }
                }
            }

        }

        .prefs {
            max-width: 367px;
            margin-left: 45px;
            margin-top: 70px;
            display: flex;
            flex-direction: column;
            gap: 50px;
            align-items: flex-start;

            .prefs--item {
                display: flex;
                align-items: center;
                gap: 15px;

                h2 {
                    font-family: var(--title-font);
                    font-size: 30px;
                }

                p {
                    font-family: var(--regular-font);
                    font-size: 12px;
                    text-transform: uppercase;
                    max-width: 205px;
                }

                &:nth-child(2n+1) {
                    p {
                        color: var(--blue-light-color);
                    }
                }

                &:nth-child(2n) {
                    align-self: flex-end;

                    h2 {
                        color: var(--blue-color);
                    }
                }
            }
        }

        .image {
            position: absolute;
            right: 2%;
            top: 50px;
            z-index: -1;

            img {
                width: 650px;
            }

            @media (max-width: 1230px) {
                bottom: 0;
                top: unset;
                right: 0;
                img {
                    width: 500px;
                }
            }
        }

        .download--frame {
            display: flex;
            margin-top: 40px;
            max-width: 475px;
            justify-content: center;

            span {
                padding: 18px 78px;
                font-family: var(--regular-font);
                font-size: 12px;
                font-weight: bold;
                color: var(--white-color);
                background: var(--grey-light-color);
                position: relative;
                transition: all 0.3s ease-in-out;
                z-index: 1;
                cursor: pointer;

                &:after {
                    position: absolute;
                    content: '';
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    transform: scale(1.2, 1.2);
                    opacity: 0;
                    border: 2px solid var(--blue-color);
                    transition: all 0.3s ease-in-out;
                    z-index: -1;
                }

                &:hover {
                    color: var(--blue-color);
                    //background-color: var(--main-bg-color);

                    &::after {
                        opacity: 1;
                        transform: scale(1, 1);
                        z-index: 2;
                    }
                }
            }
        }
        
        @media (max-width: 1055px) {

            .unstoppable--title{
                width: fit-content;
                margin-left: 0;
                align-self: center;
                margin-top: 60px;
                .you{
                    display: flex;
                    gap: 70px;
                }
                .stop{
                    margin-left: 0;
                    position: relative;
                    .no::before{
                        bottom: 15%;
                        top: unset;
                        height: 1000%;
                    }
                    &::after{
                        content: '';
                        position: absolute;
                        bottom: 0px;
                        height: 4px;
                        width: 1000%;
                        left: 0;
                        z-index: 2;
                        background-color: var(--blue-color);
                    }

                }
            }

            .download--frame{
                margin-top: 80px;

            }
        }

        @media (max-width: 840px){
            margin-right: -30px;
            margin-left: -30px;
            padding-left: 30px;
            padding-right: 30px;
            .image{
                inset: 0;
                overflow: hidden;
                img{
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 700px;
                    opacity: .3;
                    object-fit: none;
                    height: 600px;
                }
            }
            .download--frame{
                max-width: unset;
            }
            .prefs{
                margin-left: 0;
                justify-self: center;
                align-self: center;
                width: 100%;
                &--item p{
                    font-weight: bolder;
                }
            }
        }
        @media (max-width: 600px){
            min-height: 650px;
            .unstoppable--title{
                margin-top: 30px;
                .you, .stop{
                    font-size: 30px !important;
                }
                .you{
                    gap: 45px !important;
                }
            }

        }
    }
}
</style>