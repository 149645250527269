<template>
    <nav class="nav">
        <router-link :to="{name: 'Main'}" class="logo"><img src="@/assets/images/New_Images/Logo.svg" alt="#">
        </router-link>
        <ul class="nav-list">
            <li class="nav-list__item">
                <router-link :to="{name: 'Main'}" class="nav-list__link">Главная</router-link>
            </li>
            <li class="nav-list__item">
                <router-link :to="{name: 'Instruction'}" class="nav-list__link">Инструкция</router-link>
            </li>
            <li class="nav-list__item">
                <router-link :to="{name: 'Products'}" class="nav-list__link">Продукты</router-link>
            </li>
            <li class="nav-list__item">
                <router-link :to="{name: 'Contacts'}" class="nav-list__link">Контакты</router-link>
            </li>
        </ul>
        <div class="burger" @click="openMenu"></div>
        <router-link :to="{name: 'Cart'}" class="cart">Корзина
            <div v-if="countSelected>0" class="cart__count">{{ countSelected }}</div>
        </router-link>
    </nav>
</template>

<script>
import debounce from 'lodash/debounce';
import {mapGetters} from "vuex";

export default {
    name: "HeaderMenu",
    data() {
        return {
            activeFixed: false
        }
    },
    computed: {
        ...mapGetters({
            countSelected: 'getSelectedSpecksCount'
        })
    },
    methods: {
        handleScroll() {
            let scroll = window.scrollY;
            if (scroll >= window.innerHeight * 0.8 && !this.activeFixed) {
                document.querySelector('.nav').classList.add('nav--fixed')
                this.activeFixed = true;
            }
            if (scroll < window.innerHeight * 0.8 && this.activeFixed) {
                document.querySelector(".nav").classList.add("nav--close")
                this.activeFixed = false;
                setTimeout(() => {
                    document.querySelector(".nav").classList.remove("nav--fixed", "nav--close")
                }, 200)
            }
        },
        openMenu() {
            let burgerMenu = this.$el.querySelector(".nav-list")
            let burgerBtn = this.$el.querySelector(".burger")
            if (burgerMenu.classList.contains("active")) {
                burgerMenu.style.transition = 'all 0.3s ease-out'
                burgerMenu.classList.remove('active')
                setTimeout(() => {
                    burgerMenu.style.transition = "none"
                    burgerBtn.style.filter = 'invert(10%) sepia(4%) saturate(3354%) hue-rotate(186deg) brightness(92%) contrast(83%)'
                }, 250)
            } else {
                burgerMenu.classList.add('active')
                burgerBtn.style.filter = 'invert(80%) sepia(54%) saturate(6655%) hue-rotate(11deg) brightness(98%) contrast(99%)'
            }
        },

    },
    mounted() {
        this.handleDebouncedScroll = debounce(this.handleScroll, 7);
        window.addEventListener('scroll', this.handleDebouncedScroll);
        this.handleScroll();
    },
    beforeDestroy() {
        // I switched the example from `destroyed` to `beforeDestroy`
        // to exercise your mind a bit. This lifecycle method works too.
        window.removeEventListener('scroll', this.handleDebouncedScroll);
    }
}
</script>

<style lang="scss">
.nav {
    display: grid;
    grid-template-columns: 1fr 10fr 1fr;
    gap: 15px;
    position: absolute;
    width: 100%;
    z-index: 2;
    text-transform: uppercase;
    transition: .5s;
    padding: 0 calc((100vw - 1330px) / 2 + 21px);
    &.nav--fixed {
        position: fixed;
        z-index: 3;
        top: 0;
        animation: openHeaderMenu .6s ease;
        background-color: var(--main-bg-color);

        &.nav--close {
            animation: closeHeaderMenu .4s ease;
            background-color: transparent;
        }
    }

    a {
        color: var(--blue-light-color);
    }

    font-family: var(--regular-font);
    font-size: 12px;
    font-weight: bold;

    .nav-list {
        justify-self: center;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    .nav-list__link, .cart {
        padding: 11px;
        position: relative;
        transition: all 0.3s ease-in-out;

        &::after {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            transform: scale(1.3, 1.4);
            opacity: 0;
            border-bottom: 2px solid var(--orange-color);
            transition: all 0.3s ease-in-out;
        }

        &:hover, &.router-link-exact-active:not(.cart) {
            color: var(--orange-color);

            &::after {

                opacity: 1;
                transform: scale(1, 1);
            }
        }


    }

    a, ul {
        align-self: center;
    }

    .burger {
        display: none;
    }

    .cart {
        justify-self: end;
        color: var(--main-bg-color);
        z-index: 1;

        &::before {
            transition: all 0.3s ease-in-out;
            z-index: -1;
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(var(--orange-color) 35%, var(--orange-dark-color));
        }

        &::after {
            border: 2px solid var(--orange-color);
        }

        &__count {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: -5px;
            right: -5px;
            border-radius: 50%;
            background-color: var(--main-bg-color);
            height: 20px;
            width: 20px;
            z-index: 10;
            font-size: 11px;
            color: var(--blue-light-color);
        }

        &:hover {
            background: var(--main-bg-color);

            &::after {
                opacity: 1;
                transform: scale(1, 1);
            }

            &::before {
                opacity: 0;
            }
        }
    }

    @media (max-width: 1330px) {
        padding: 0 10px;
    }
    @media (max-width: 660px) {
        .nav-list {
            gap: 5px;
        }
        .nav-list__link, .nav .cart {
            padding: 5px;
            font-size: 10px;
        }
    }

    @media (max-width: 490px) {
        grid-template-columns: 1fr 1fr;
        height: 50px;
        overflow: visible;
        .logo {
            z-index: 3;

            img {
                height: 50px;
            }
        }
        .nav-list {
            position: absolute;
            width: 100%;
            flex-direction: column;
            gap: 20px;
            font-size: 16px;
            top: 0;
            padding: 70px;
            background-color: var(--dark-bg-color);
            //overflow: hidden;
            transform: translateY(-100%);
            height: 100vh;
            z-index: 2;

        }
        .active {
            transform: translateY(0);
            transition: transform 0.4s ease-out !important;
        }
        .burger {
            position: absolute;
            display: block;
            width: 30px;
            height: 30px;
            top: 10px;
            right: 100px;
            background-image: url(~@/assets/images/New_Images/burger.png);
            filter: invert(10%) sepia(4%) saturate(3354%) hue-rotate(186deg) brightness(92%) contrast(83%);
            z-index: 3;
        }
        .cart {
            padding: 10px;
            font-size: 10px;
            z-index: 3;
        }
    }

    @keyframes openHeaderMenu {
        from {
            opacity: 0;
            transform: translateY(-20%);
            filter: blur(10px);
        }
        to {
            opacity: 1;
            transform: translateY(0%);
            filter: blur(0);
        }

    }
    @keyframes closeHeaderMenu {
        from {
            opacity: 1;
            transform: translateY(0%);
            filter: blur(0);
        }
        to {
            opacity: 0;
            transform: translateY(-20%);
            filter: blur(10px);
        }
    }

}
</style>