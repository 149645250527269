<template>
    <section class="shop">
        <div class="container">
            <div class="content">
                <div class="slider__left">
                    <div class="classlist">
                        <img class="classItem" v-for="classitem in classes" :key="classitem.classID"
                             :class="`classImage-${classitem.classID}`"
                             :src="require(`@/assets/images/New_Images/classImage/${classitem.classImage}`)">
                    </div>
                    <div class="class--icons">
                        <img v-for="classitem in classes" :key="classitem.classID"
                             :class="`classIcon-${classitem.classID}`"
                             :src="require(`@/assets/images/New_Images/classIcons/${classitem.classFastIcon}`)"
                             @click="setActiveClass(classitem.classID)"
                             loading="lazy"
                             alt="">
                    </div>
                    <div class="button--addAll">
                        <button @click="addAllSpecks">Добавить все</button>
                    </div>
                </div>
                <div class="slider__right">
                    <div class="speck--item" v-for="item in filteredSpecks" :key="item.speckID"
                         :class="'speck-'+item.speckID">
                        <div class="speck--title"
                             :class="[item.addCard ? 'greyImage' : '']"
                             :style="{'color':item.color}"><h3>{{ item.speckName }}</h3></div>
                        <div class="speck--description"
                             :class="[item.addCard ? 'greyImage' : '']"><p>{{ item.speckDetails }}</p></div>
                        <img class="speck--img"
                             :class="[item.addCard ? 'greyImage' : '']"
                             :src="require(`@/assets/images/New_Images/speckImage/${item.speckImage}`)"
                             loading="lazy"
                             alt="">
                    </div>
                    <div class="speck--buttons">
                        <button class="speck--buttons__up btn" @click="changeSpeckSlide(-1)"><img
                                src="@/assets/images/New_Images/arrow.svg" alt=""></button>
                        <button class="speck--buttons__down btn" @click="changeSpeckSlide(1)"><img
                                src="@/assets/images/New_Images/arrow.svg" alt=""></button>
                        <button class="speck--buttons__add btn" @click="addSpeckInCart"><img src="@/assets/images/New_Images/plus.png"
                                                                     alt=""></button>
                    </div>
                </div>
                <div class="current--class--title"><h2>{{ className }}</h2></div>
            </div>
        </div>
    </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "Shop",
    data() {
        return {
            currentClassID: 0,
            className: '',
            currentSpeckID: Number,
        }
    },
    computed: {
        ...mapGetters({
            classes: 'getClasses',
        }),
        filteredSpecks() {
            return this.classes[this.currentClassID].specks
        },
    },
    methods: {
        setRandomCurrentClass() {
            this.currentClassID = Math.floor(Math.random() * this.classes.length)
            if (!this.currentClassID){
                this.changeCurrentActiveClass()
                this.setNewCurrentSpeck()
            }
        },
        setActiveClass(newValIdClass) {
            this.currentClassID = newValIdClass;
        },
        changeCurrentActiveClass(newVal = 0, oldVal = 0) {
            const timeout = 200;
            let oldIcon = this.$el.querySelector(".classIcon-" + oldVal);
            let newIcon = this.$el.querySelector(".classIcon-" + newVal);
            oldIcon.classList.remove("activeIcon")
            newIcon.classList.add("activeIcon")
            //----------------------------------------------------
            let activeNameClass = this.$el.querySelector(".current--class--title")
            if (activeNameClass.classList.contains('activeClassName')) {
                activeNameClass.classList.add('disableClassName')
                setTimeout(() => {
                    activeNameClass.classList.remove('activeClassName', 'disableClassName');
                    this.className = this.classes[newVal].className;
                }, 200)
                setTimeout(() => {
                    activeNameClass.classList.add('activeClassName')
                }, 250)
            } else {
                this.className = this.classes[newVal].className;
                activeNameClass.classList.add('activeClassName')
            }


            //----------------------------------------------------
            let newImg = this.$el.querySelector(".classImage-" + newVal);
            let oldImg = this.$el.querySelector(".classImage-" + oldVal);
            oldImg.classList.remove("active")
            setTimeout(() => {
                newImg.classList.add("active")
            }, timeout)
        },
        setNewCurrentSpeck(newSpeckId = undefined) {
            if (newSpeckId === undefined)
                newSpeckId = this.filteredSpecks[0].speckID;
            let newSpeckObject = this.$el.querySelector('.speck-' + newSpeckId);
            if (newSpeckObject === null) {
                setTimeout(() => {
                    this.setNewCurrentSpeck(newSpeckId)
                }, 100)
                return 0;
            }

            let oldSpeckObject = this.$el.querySelector(".speck--item.active");
            if (oldSpeckObject !== null && oldSpeckObject !== undefined) {
                oldSpeckObject.classList.remove('active')
            }
            setTimeout(() => {
                newSpeckObject.classList.add('active')
            }, 300)
            this.currentSpeckID = newSpeckId;
        },
        changeSpeckSlide(pos) {
            let speckIDArray = [];
            for (let item of this.filteredSpecks) {
                speckIDArray.push(item.speckID)
            }
            speckIDArray.sort();
            if (this.currentSpeckID + pos < Math.min(...speckIDArray))
                this.setNewCurrentSpeck(Math.max(...speckIDArray))
            else if (this.currentSpeckID + pos > Math.max(...speckIDArray))
                this.setNewCurrentSpeck(Math.min(...speckIDArray))
            else
                this.setNewCurrentSpeck(this.currentSpeckID += pos)
        },
        addSpeckInCart(){
            let speck = this.filteredSpecks.find(speck => speck.speckID === this.currentSpeckID)
            let className = this.className
            if(speck.addCard)
                return
            this.$store.commit('addSpeck', {speck, className})
        },
        addAllSpecks(){
            this.$store.commit('addAllSpeck')
        }
    },
    watch: {
        currentClassID(newVal, oldVal) {
            this.changeCurrentActiveClass(newVal, oldVal)
            this.setNewCurrentSpeck()
        },
    },
    mounted() {
        this.setRandomCurrentClass();
    },
}
</script>

<style lang="scss">
.shop {
    background-color: var(--main-bg-color);

    .content {
        background-color: var(--dark-bg-color);
        min-height: 820px;
        display: flex;
        flex-direction: row;
        height: 100%;
        padding: 120px 188px 60px 188px;
        gap: 80px;
        position: relative;
        overflow: hidden;
        justify-content: space-between;

        .current--class--title {
            position: absolute;
            text-align: center;
            color: var(--orange-color);
            font-family: var(--title-font);
            width: 100%;
            top: 40px;
            left: 0;
            right: 0;
            font-size: 48px;
            filter: blur(20px);
            transform: translateX(-40%);
            opacity: 0;

            &.activeClassName {
                opacity: 0;
                z-index: 2;
                animation: showContent .2s .1s ease-in-out 1 forwards;

            }

            &.disableClassName {
                opacity: 1;
                filter: blur(0);
                transform: translateX(0);
                animation: hideContent .2s ease-in-out 1 forwards;
            }
        }

        .slider__left {
            max-width: 360px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;

            .classlist {
                width: 100%;
                height: 350px;
                position: relative;
                overflow: hidden;

                .classItem {
                    position: absolute;
                    inset: 0 0 0 0;
                    object-fit: contain;
                    opacity: 0;
                    transition: all 0.5s ease-in-out;
                    height: 100%;
                    width: 100%;
                    filter: blur(10px);

                    &.active {
                        opacity: 1;
                        filter: blur(0);
                    }
                }

            }

            .class--icons {
                display: grid;
                grid-template-rows: 1fr 1fr;
                grid-template-columns: repeat(5, 1fr);
                margin-top: 10px;

                img {
                    transition: all 0.3s ease-in-out;
                    cursor: pointer;
                    opacity: 0.85;
                }

                img:hover, .activeIcon {
                    filter: drop-shadow(0 0 15px var(--orange-color));
                    opacity: 1;
                }
            }

            .button--addAll {
                color: var(--orange-color);
                font-family: var(--regular-font);
                font-size: 12px;
                margin-top: auto;

                button {
                    height: 50px;
                    width: 207px;
                    position: relative;
                    transition: all 0.3s ease-in-out;
                    font-weight: bold;
                    z-index: 1;
                    text-transform: uppercase;
                    background-color: var(--grey-color);

                    &::after {
                        position: absolute;
                        content: '';
                        height: 100%;
                        width: 100%;
                        inset: 0;
                        opacity: 0;
                        transform: scale(1.2, 1.4);
                        border: 2px solid var(--blue-color);
                        transition: all .3s ease-in-out;
                    }

                    &:hover {
                        background-color: var(--grey-light-color);
                        color: var(--blue-color);

                        &::after {
                            opacity: 1;
                            transform: scale(1, 1);
                            z-index: 2;

                        }
                    }
                }
            }
        }

        .slider__right {
            width: 320px;
            min-height: 100%;
            position: relative;

            .speck--item {
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                height: 100%;
                width: 100%;
                font-family: var(--regular-font);
                display: flex;
                flex-direction: column;
                justify-content: start;
                opacity: 0;
                filter: blur(20px);
                transition: all .3s ease-in-out;

                &.active {
                    opacity: 1;
                    filter: blur(0);
                }
            }

            .speck--title {
                transition: filter 0.3s ease-out;
                font-weight: bolder;
                font-size: 18px;
                text-transform: uppercase;
                width: fit-content;
                position: relative;

                &::after {
                    position: absolute;
                    top: 30px;
                    content: '';
                    height: 2px;
                    width: 100%;
                    min-width: 150px;
                    left: 0px;
                    background-color: var(--orange-color);
                }

            }

            .speck--description {
                margin-top: 33px;
                color: var(--blue-light-color);
                text-transform: uppercase;
                text-align: justify;
                font-size: 12px;
                line-height: .95;
                transition: filter 0.3s ease-out;
            }

            .speck--img {
                max-height: 490px;
                max-width: 100%;
                object-fit: contain;
                margin-top: auto;
                transition: filter .3s ease-out;
            }
            .greyImage{
                filter: grayscale(1);
            }


            .speck--buttons {
                position: absolute;
                gap: 10px;
                display: flex;
                flex-direction: column;
                font-family: monospace;
                font-weight: bold;
                font-size: 40px;
                text-align: center;
                left: -60px;

                &__up {
                    img {
                        transform: rotate(-180deg);
                    }
                }

                &__down {
                }

                &__add {
                    margin-top: 25px;
                }

                .btn {
                    width: 35px;
                    height: 35px;
                    background-color: var(--grey-color);
                    border: 1px solid var(--grey-light-color);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: all 0.3s ease-out;
                    &:hover{
                        background-color: var(--grey-light-color);
                        border: 1px solid var(--orange-color);
                    }

                }
            }
        }

        @media (max-width: 1180px) {
            padding: 120px 5px 60px 5px;
        }
        @media (max-width: 810px) {
            margin: 0 -30px;
            padding: 100px 30px 60px 30px;
        }
        @media (max-width: 800px) {
            flex-direction: column;
            justify-content: start;
            padding: 50px 30px 0px 30px;
            min-height: 1330px;
            gap: 30px;
            .current--class--title{
                font-size: 30px;
            }
            .slider__left {
                max-height: 700px;
                max-width: 400px;
                align-self: center;
                .classlist {
                    width: 300px !important;
                }
                .button--addAll{
                    margin-top: unset;
                }
            }
            .slider__right{
                align-self: center;
            }
        }
        @media (max-width: 460px) {
            gap: 70px;
            .slider__right{
                .speck--buttons{
                    left: 50%;
                    top: 0;
                    transform: rotate(-90deg) translateY(-15%) translateX(300%);
                }
            }
        }
    }


}

@keyframes showContent {
    to {
        opacity: 1;
        filter: blur(0);
        transform: translateX(0);
    }
}

@keyframes hideContent {
    to {
        opacity: 0;
        filter: blur(20px);
        transform: translateX(40%);
    }
}
</style>