<template>
    <div class="UpArrow" @click = "scrollTop"></div>
</template>

<script>
import debounce from 'lodash/debounce';

export default {
    name: "UpArrow",
    data() {
        return {
            activeFixed: false
        }
    },
    methods: {
        handleScroll() {
            let scroll = window.scrollY;
            if (scroll >= window.innerHeight* 0.8 && !this.activeFixed) {
                document.querySelector('.UpArrow').classList.add('UpArrow--fixed')
                this.activeFixed = true;
            }
            if (scroll < window.innerHeight* 0.8 && this.activeFixed) {
                document.querySelector(".UpArrow").classList.remove("UpArrow--fixed")
                this.activeFixed = false;
            }
        },
        scrollTop(){
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }

    },
    mounted() {
        this.handleDebouncedScroll = debounce(this.handleScroll, 100);
        window.addEventListener('scroll', this.handleDebouncedScroll);
    },
    beforeDestroy() {
        // I switched the example from `destroyed` to `beforeDestroy`
        // to exercise your mind a bit. This lifecycle method works too.
        window.removeEventListener('scroll', this.handleDebouncedScroll);
    }
}
</script>

<style lang="scss">
.UpArrow {
    position: fixed;
    background-color: var(--orange-color);
    bottom: 3%;
    right: 3%;
    opacity: 0;
    z-index: 0;
    transition: all 0.3s ease;
    height: 50px;
    width: 50px;
    cursor: pointer;
    &:hover{
        background-color: var(--main-bg-color);
        &:after{
            opacity: 1;
            transform: scale(1,1);
        }
        &:before{
            filter: none;

        }
    }

    &:after{
        content: '';
        position: absolute;
        display: block;
        inset: 0 0 0 0;
        transform: scale(1.3, 1.3);
        border: 2px solid var(--orange-color);
        z-index: 3;
        opacity: 0;
        height: 100%;
        width: 100%;
        transition: all .3s ease-in-out;
    }

    &.UpArrow--fixed {
        opacity: 1;
        z-index: 3;
    }

    &::before {
        display: block;
        content: '';
        inset: 0 0 0 0;
        height: 100%;
        width: 100%;
        background-image: url(~@/assets/images/New_Images/arrow.svg);
        background-repeat: no-repeat;
        background-position: center center;
        transform: rotate(180deg);
        transition: all .3s ease-in-out;
        filter: brightness(0) saturate(100%) invert(5%) sepia(62%) saturate(941%) hue-rotate(176deg) brightness(92%) contrast(103%)
    }
    
    @media (max-width: 600px) {
        height: 30px;
        width: 30px;
    }
}
</style>