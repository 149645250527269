<template>
    <div class="mc">
        <HeaderMenu/>
        <Header/>
        <Product/>
        <Advantages/>
        <Unstoppable/>
        <Tariff/>
        <Shop/>
        <Footer/>
        <UpArrow/>
        <NavigationPanel/>
    </div>
</template>


<script>


import HeaderMenu from "@/components/HeaderMenu";
import Header from "@/components/MainPage/Header";
import Product from "@/components/MainPage/Product";
import Advantages from "@/components/MainPage/Advantages";
import Unstoppable from "@/components/MainPage/Unstoppable";
import Tariff from "@/components/MainPage/Tariff";
import Shop from "@/components/MainPage/Shop";
import Footer from "@/components/Footer";
import UpArrow from "@/components/UpArrow";
import NavigationPanel from "@/components/MainPage/NavigationPanel";


export default {
    name: "MainPage",
    components: {NavigationPanel, UpArrow, Footer, Shop, Tariff, Unstoppable, Advantages, Product, HeaderMenu, Header},
}
</script>

<style lang="scss">
.mc {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    min-height: 100vh;

    .container {
        max-width: 1330px;
        padding: 0 21px;
        margin: 0 auto;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
}
</style>
