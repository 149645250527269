<template>
    <section class="advantages">
        <div class="container">
            <div class="content">
                <div class="content__title">
                    <h2><span class="we">Мы </span><span class="best">лучшие</span></h2>
                </div>
                <div class="content--categories">
                    <img class="arrow_1" src="@/assets/images/New_Images/arrow_1.png">
                    <div class="content--categories__safety">
                        <h3>Приоритет безопасность</h3>
                        <ul class="categories--concrete">
                            <li class="advantage"><img src="@/assets/images/New_Images/time.svg" alt=""><span>Регулярные обновления</span>
                            </li>
                            <li class="advantage"><img src="@/assets/images/New_Images/lock.svg" alt=""><span>Не встраиваемся в игру</span>
                            </li>
                            <li class="advantage"><img src="@/assets/images/New_Images/data.svg" alt=""><span>Не собираем данных</span>
                            </li>
                            <li class="advantage"><img src="@/assets/images/New_Images/eye.svg" alt=""><span>Античит “No Detect”</span>
                            </li>
                        </ul>
                    </div>
                    <img class="arrow_2" src="@/assets/images/New_Images/arrow_2.png">
                    <div class="content--categories__practices">
                        <h3>Лучшие практики</h3>
                        <ul class="categories--concrete">
                            <li class="advantage"><img src="@/assets/images/New_Images/brush.svg"><span>Современный дизайн</span>
                            </li>
                            <li class="advantage"><img src="@/assets/images/New_Images/brain.svg" alt=""><span>Понятный интерфейс</span>
                            </li>
                            <li class="advantage"><img src="@/assets/images/New_Images/medal.svg" alt=""><span>Производительность</span>
                            </li>
                            <li class="advantage"><img src="@/assets/images/New_Images/brain.svg" alt=""><span>Скидки клиентам</span>
                            </li>
                        </ul>
                    </div>
                    <img class="arrow_3" src="@/assets/images/New_Images/arrow_3.png">
                    <div class="content--categories__range">
                        <h3>Королевский ассортимент</h3>
                        <ul class="categories--concrete">
                            <li class="advantage"><img src="@/assets/images/New_Images/low.svg" alt=""><span>Минимальные требования к железу</span>
                            </li>
                            <li class="advantage"><img src="@/assets/images/New_Images/setting.svg" alt=""><span>Настройка в лаунчере и игре</span>
                            </li>
                            <li class="advantage"><img src="@/assets/images/New_Images/stack.svg" alt=""><span>В расспоряжении 29 спеков</span>
                            </li>
                            <li class="advantage"><img src="@/assets/images/New_Images/process.svg" alt=""><span>Поддержка всех серверов</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="content__decoration1"></div>
                <div class="content__decoration2"></div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Advantages"
}
</script>

<style lang="scss">
.advantages {
    min-height: 850px;
    background-color: var(--main-bg-color);

    .content {
        margin-top: 10px;
        min-height: 840px;
        background: var(--orange-color);
        position: relative;
        display: flex;
        flex-direction: column;

        &::before {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            background-image: url(~@/assets/images/New_Images/Map.png);
            background-size: cover;
            background-position: center center;
            top: 0;
            left: 0;
            background-repeat: no-repeat;
        }

        &::after {
            content: '';
            position: absolute;
            height: 109%;
            width: 100%;
            background-image: url(~@/assets/images/New_Images/ZulJin.png);
            top: -80px;
            left: 50%;
            transform: translateX(-961px);
            background-repeat: no-repeat;
            z-index: 1;
        }

        &__title {
            font-family: var(--title-font);
            line-height: 1.2;
            font-size: 48px;
            color: var(--blue-color);
            padding-top: 58px;
            margin-left: 142px;

            &::before {
                content: "Мы";
                position: absolute;
                color: var(--orange-color);
                z-index: 2;
            }

            .we {
                color: transparent;
                position: relative;
                z-index: 1;

                &::before {
                    content: '';
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    height: 67px;
                    width: 3000px;
                    background-color: var(--main-bg-color);
                    transform: translateY(-50%);
                    z-index: -1;

                }
            }

            @media (max-width: 600px) {
                font-size: 30px;
                .we::before{
                    height: 100%;
                    right: 5px;
                }
            }

        }

        &__decoration1 {
            position: absolute;
            left: 32.5%;
            height: 100%;
            top: 0;
            width: 4px;
            background: linear-gradient(var(--main-bg-color) 45%, transparent 99%);
        }

        &__decoration2 {
            position: absolute;
            left: 0;
            height: 4px;
            top: 56.5%;
            width: 100%;
            background-color: var(--main-bg-color);
            @media (max-width: 600px) {
                top: 13% !important;
            }
        }

        .content--categories {
            position: relative;
            font-family: var(--regular-font);
            flex: 1;

            h3 {
                font-size: 16px;
                font-weight: bold;
                text-transform: uppercase;
            }

            .arrow_1 {
                position: absolute;
                top: 8%;
                left: 50%;
                transform: translateX(25px);
            }

            .arrow_2 {
                position: absolute;
                top: 55%;
                left: 50%;
                transform: translateX(-342px);
            }

            .arrow_3 {
                position: absolute;
                top: 54.5%;
                left: 50%;
                transform: translateX(215px);
            }

            &__safety {
                position: absolute;
                width: 318px;
                height: 248px;
                background-color: rgba(0, 0, 0, 20%);
                border-radius: 10px;
                left: 50%;
                transform: translateX(210px);
                top: 10%;
                padding-top: 30px;
                display: flex;
                flex-direction: column;
                align-items: center;
                box-shadow: 5px 10px 7px 2px rgba(26, 34, 44, 0.2);
                z-index: 2;
                transition: all 0.5s ease;

                &:hover{
                    background-color: rgba(0, 0, 0, 30%);
                    box-shadow: 5px 10px 30px 30px rgba(26, 34, 44, 0.2);
                }
            }

            &__practices {
                position: absolute;
                width: 206px;
                height: 291px;
                background-color: rgba(0, 0, 0, 20%);
                border-radius: 10px;
                left: 50%;
                transform: translateX(-549px);
                top: 57%;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-top: 30px;
                box-shadow: 5px 10px 7px 2px rgba(26, 34, 44, 0.2);
                transition: all 0.5s ease;
                z-index: 2;

                &:hover{
                    background-color: rgba(0, 0, 0, 30%);
                    box-shadow: 5px 10px 30px 30px rgba(26, 34, 44, 0.2);
                }
            }

            &__range {
                position: absolute;
                width: 318px;
                height: 291px;
                background-color: rgba(0, 0, 0, 20%);
                border-radius: 10px;
                left: 50%;
                top: 57%;
                transform: translateX(-95px);
                padding-top: 30px;
                display: flex;
                flex-direction: column;
                align-items: center;
                box-shadow: 5px 10px 7px 2px rgba(26, 34, 44, 0.2);
                transition: all 0.5s ease;
                z-index: 2;

                &:hover{
                    background-color: rgba(0, 0, 0, 30%);
                    box-shadow: 5px 10px 30px 30px rgba(26, 34, 44, 0.2);
                }
            }

            .categories--concrete {
                font-size: 12px;
                color: var(--blue-light-color);
                margin-top: 55px;
                display: flex;
                flex-direction: column;
                gap: 15px;
                line-height: 1.2;

                .advantage {
                    display: flex;
                    align-items: end;
                    gap: 4px;

                    img {
                        max-width: 20px;
                        max-height: 20px;
                        filter: brightness(0) saturate(100%) invert(5%) sepia(89%) saturate(589%) hue-rotate(177deg) brightness(99%) contrast(103%);
                    }
                }
            }

            @media (max-width: 1155px) {
                .arrow_1, .arrow_2, .arrow_3{
                    display: none;
                }
                &__safety{
                    transform: translateX(0);
                }
                &__practices{
                    transform: translateX(-390px);
                }
                &__range{
                    transform: translateX(-70px);
                }

            }
        }

        @media (max-width: 920px) {
            margin-left: -30px;
            margin-right: -30px;
            padding-left: 30px;
            padding-right: 30px;
            padding-bottom: 30px;
            &::after{
                background-image: none;
            }
            &__title{
                margin-left: 0;
                position: unset;
                text-align: center;
                z-index: 2;
                &::before{
                    display: none;
                }
                .we{
                    color: var(--orange-color);
                }
            }
            &__decoration1{
                left: 2%;
            }
            &__decoration2{
                top: 14.5%;
            }
            .content--categories{
                display: grid;
                grid-template-rows: repeat(3, 1fr);
                grid-gap: 20px;
                margin-top: 30px;

                &__safety, &__practices, &__range{
                    position: unset;
                    transform: none;
                    width: inherit;
                    height: 100%;

                    .categories--concrete{
                        margin-top: 20px;
                        margin-bottom: 20px;
                    }
                }



            }
        }
    }
}

</style>