var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"tariff--sec"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"content"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"tariff--products"},[_c('div',{staticClass:"tariff--products__start product--anim"},[_c('div',{staticClass:"product--anim--bg product--anim__start"}),_c('div',{staticClass:"start--product card--product"},[_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"cost"},[_c('h2',[_vm._v(_vm._s(_vm.costBeginner)+" р.")])])])]),_c('div',{staticClass:"tariff--products__medium product--anim"},[_c('div',{staticClass:"product--anim--bg product--anim__medium"}),_c('div',{staticClass:"medium--product card--product"},[_vm._m(5),_vm._m(6),_vm._m(7),_c('div',{staticClass:"cost"},[_c('h2',[_vm._v(_vm._s(Math.floor(_vm.costStandard * 3))+" Р.")])])])]),_c('div',{staticClass:"tariff--products__vip product--anim"},[_c('div',{staticClass:"product--anim--bg product--anim__vip"}),_c('div',{staticClass:"vip--product card--product"},[_vm._m(8),_vm._m(9),_vm._m(10),_c('div',{staticClass:"cost"},[_c('h2',[_vm._v(_vm._s(_vm.costVip)+" Р.")])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tariff--title"},[_c('h2',[_vm._v("Выбери свой "),_c('span',[_vm._v("путь")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tariff--description"},[_c('p',[_vm._v("Не сомневайся в том")]),_c('p',[_vm._v("чего всегда ЖАЖДАЛ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('h2',[_vm._v("START")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tariff"},[_c('ul',{staticClass:"tariff--list"},[_c('li',{staticClass:"tariff--list__item"},[_vm._v("1 спек")]),_c('li',{staticClass:"tariff--list__item"},[_vm._v("Доступ в DC")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"description"},[_c('span',{staticClass:"firstline"},[_vm._v("Рыцарь ищущий свой путь")]),_c('span',{staticClass:"secondline"},[_vm._v("Один спек на месяц")]),_c('span',{staticClass:"thirstline"},[_vm._v("отличный способ начать")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('h2',[_vm._v("MEDIUM")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tariff"},[_c('ul',{staticClass:"tariff--list"},[_c('li',{staticClass:"tariff--list__item"},[_vm._v("От 3 до 5 спеков")]),_c('li',{staticClass:"tariff--list__item"},[_vm._v("Доступ в DC")]),_c('li',{staticClass:"tariff--list__item"},[_vm._v("Приятные бонусы для продления")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"description"},[_c('span',{staticClass:"firstline"},[_vm._v("Ты всегда хотел это попробовать")]),_c('span',{staticClass:"secondline"},[_vm._v("От 3 до 5 спеков на месяц")]),_c('span',{staticClass:"thirstline"},[_vm._v("все в одном пакете")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('h2',[_vm._v("VIP")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tariff"},[_c('ul',{staticClass:"tariff--list"},[_c('li',{staticClass:"tariff--list__item"},[_vm._v("29 доступных спеков")]),_c('li',{staticClass:"tariff--list__item"},[_vm._v("Доступ в PRIVATE DC")]),_c('li',{staticClass:"tariff--list__item"},[_vm._v("Полное сопровождение")]),_c('li',{staticClass:"tariff--list__item"},[_vm._v("Месяц без ограничений")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"description"},[_c('span',{staticClass:"firstline"},[_vm._v("Пакет услуг истинного короля")]),_c('span',{staticClass:"secondline"},[_vm._v("Полный доступ ко всем")]),_c('span',{staticClass:"thirstline"},[_vm._v("возможностям программы")])])
}]

export { render, staticRenderFns }