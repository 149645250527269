import Vue from 'vue';
import Vuex from 'vuex';
import axios from "axios";

Vue.use(Vuex);

export const store = new Vuex.Store({

    state: {
        scrollPosition: 0,
        costBase: 150,
        costVIP: 999,
        selectedSpeck: [],
        classes: [
            {
                classID: 0,
                className: 'Рыцарь смерти',
                classImage: '0_deathKnight.png',
                classFastIcon: 'DeathKnight.webp',
                classSlogan: 'Рыцарь смерти - вестник погибели',
                specks: [
                    {
                        speckID: 0,
                        speckName: 'Кровь',
                        specProlog: 'Только',
                        specSlogan: 'Покажет истину',
                        speckDetails: 'Рыцарь избравший пути Крови навсегда становится частью сокрального мира. Только истинный король способен обуздать силу этого верного и поистине кровожадного слуги.',
                        color: '#872222',
                        speckImage: '0_DK_blood.jpg',
                        addCard: false
                    },
                    {
                        speckID: 1,
                        speckName: 'Лед',
                        specProlog: 'Черный',
                        specSlogan: 'Пленяет души',
                        speckDetails: 'На страже врат крепости Ледяного трона несут свою службу мертвые покорители северных пустошей Нордскола. Ходят легенды о их непоколебимой стойкости, не стоит пытаться узнать правда ли это.',
                        color: '#118e9b',
                        speckImage: '1_DK_frost.jpg',
                        addCard: false
                    },
                    {
                        speckID: 2,
                        speckName: 'Нечестивость',
                        specProlog: '',
                        specSlogan: 'Погубит мир',
                        speckDetails: 'Страшные эксперименты над живой материей, над сутью её естества превратили падших героев в узников собственного тщеславия, при этом даровав им такую силу, о которой остальные боятся даже помыслить.',
                        color: '#0E8963FF',
                        speckImage: '2_DK_unholy.jpg',
                        addCard: false
                    },
                ]
            },
            {
                classID: 1,
                className: 'Друид',
                classImage: '1_druid.png',
                classFastIcon: 'Druid.webp',
                classSlogan: 'Друид - хранитель природы',
                specks: [
                    {
                        speckID: 3,
                        speckName: 'Баланс',
                        specProlog: 'Внутренний',
                        specSlogan: 'Очищает разум',
                        speckDetails: 'Друиды, впитавшие в себя древнюю силу, что заслоняла солнце луной, в период затмения, обрели невероятную мощь, пропитанную космической энергией. Она дарует им способности, разрушительные, но создающие по своей сути.',
                        color: '#883e83',
                        speckImage: '3_DRUID_balance.jpg',
                        addCard: false
                    },
                    {
                        speckID: 4,
                        speckName: 'Зверь',
                        specProlog: 'Неистовый',
                        specSlogan: 'Внутри тебя',
                        speckDetails: 'Те хранители природы, что избрали путь зверя несут дозор в лесах и морях всего Азерота - они питаются силой древнего и сильного титана, что разлил свою магию в живительных источниках магии.',
                        speckImage: '4_DRUID_feral.jpg',
                        addCard: false
                    },
                    {
                        speckID: 5,
                        speckName: 'Исцеление',
                        specProlog: '',
                        specSlogan: 'Высшее благо',
                        speckDetails: 'Как и свои братья - друиды лекари всеми силами оберегают тот мир, в котором живут, но первоочередно они устраняют последствия разрушительных явлений, что приносят ущерб их побратимам и соратникам.',
                        color: '#acb337',
                        speckImage: '5_DRUID_restoration.jpg',
                        addCard: false
                    },
                ]
            },
            {
                classID: 2,
                className: 'Охотник',
                classImage: '2_hunter.png',
                classFastIcon: 'Hunter.webp',
                classSlogan: 'Охотник - выслеживать и приручать',
                specks: [
                    {
                        speckID: 6,
                        speckName: 'Повелитель зверей',
                        specProlog: '',
                        specSlogan: 'Владыка джунглей',
                        speckDetails: 'Очень немногочисленная каста охотников, которые самозабвенно отдают себя во власть суровых законов джунглей, их путь нелегок и тернист, но пройдя все испытания, они получают благословение лесных божеств, что даже самые сильные звери обязаны подчинятся их воле.',
                        color: '#5e7135',
                        speckImage: '6_HUNTER_beastMaster.jpg',
                        addCard: false
                    },
                    {
                        speckID: 7,
                        speckName: 'Стрельба',
                        specProlog: 'Меткая',
                        specSlogan: 'Кровь твоего врага',
                        speckDetails: 'Почти все охотники отдают предпочтение этой искусной специализации. История ещё не видела героев, которые могли бы приблизиться к ним на расстояние удара, не получив пару стрел в грудную клетку.',
                        color: '#c3c1bd',
                        speckImage: '7_HUNTER_marksmanship.jpg',
                        addCard: false
                    },
                    {
                        speckID: 8,
                        speckName: 'Выживание',
                        specProlog: 'Невероятное',
                        specSlogan: 'В суровых условиях',
                        speckDetails: 'Поразительно крепкие и хитрые охотники. Они способны удивлять своих противников, принося с этим ещё и массу тяжелых ранений. Они первоклассные мастера ловушек и трюков, доставляющих серьезные проблемы. Капканы и взрывчатка их конек.',
                        color: '#692b13',
                        speckImage: '8_HUNTER_survival.jpg',
                        addCard: false
                    },
                ]
            },
            {
                classID: 3,
                className: 'Маг',
                classImage: '3_mage.png',
                classFastIcon: 'Mage.webp',
                classSlogan: 'Маг - волшебство на кончиках пальцев',
                specks: [
                    {
                        speckID: 9,
                        speckName: 'Тайная магия',
                        specProlog: '',
                        specSlogan: 'Здесь нет шарлатанов',
                        speckDetails: 'Волшебники, которые используют эту магию, черпают свою силу из пустоты. Эти чары крайне разрушительны и при этом легки в освоении, но не стоит питать иллюзий, все имеет свою цену.',
                        color: '#543663',
                        speckImage: '9_MAGE_arcane.jpg',
                        addCard: false
                    },
                    {
                        speckID: 10,
                        speckName: 'Огонь',
                        specProlog: 'Подчинить',
                        specSlogan: 'Взрывоопасное сочетание',
                        speckDetails: 'Огромное количество волшебников пыталось подчинить эту стихию, бесчисленное множество разлетелось пеплом по ветру. Обуздать пламя - огромный труд, и лишь сильные духом обретут его мощь.',
                        color: '#732d08',
                        speckImage: '10_MAGE_fire.jpg',
                        addCard: false
                    },
                    {
                        speckID: 11,
                        speckName: 'Лед',
                        specProlog: 'Хрупкий',
                        specSlogan: 'Холодный расчет',
                        speckDetails: 'Маги посвятившие свою жизнь изучению этой стихии лишаются любых чувств и эмоций, они подобно часовому механизму неизбежно исполняют свой план, и горе тем глупцам, что бросят вызов могуществу этих волшебников.',
                        color: '#17819d',
                        speckImage: '11_MAGE_frost.jpg',
                        addCard: false
                    },
                ]
            },
            {
                classID: 4,
                className: 'Паладин',
                classImage: '4_paladin.png',
                classFastIcon: 'Paladin.webp',
                classSlogan: 'Паладин - во тьме рождается свет',
                specks: [
                    {
                        speckID: 12,
                        speckName: 'Свет',
                        specProlog: 'Божественный',
                        specSlogan: 'Укажет путь',
                        speckDetails: 'Настоящие служители света, они никогда не откажут в помощи ни войну ни путнику, которым она нужна, однако история знает случаи, когда сияние их благих целей затмевалось нечестивыми мыслями.',
                        color: '#e9e16a',
                        speckImage: '12_PALADIN_holy.jpg',
                        addCard: false
                    },
                    {
                        speckID: 13,
                        speckName: 'Защита',
                        specProlog: 'Лучшая',
                        specSlogan: 'Нападение',
                        speckDetails: 'Эти рыцари носят одни из самых прочных доспехов, которое возможно отыскать на просторах мира, они не отступают перед трудностями и всегда идут в атаку первыми, зная, что за их спинами стоят верные братья.',
                        color: '#82244e',
                        speckImage: '13_PALADIN_protection.jpg',
                        addCard: false
                    },
                    {
                        speckID: 14,
                        speckName: 'Воздаяние',
                        specProlog: '',
                        specSlogan: 'Найдет своих получателей',
                        speckDetails: 'Яростные войны света, они используют его силу, для того, чтобы бороться с нечестивостью и всеми ее проявлениями. Однако, они с радостью используют эту силу и против других врагов.',
                        color: '#f5be90',
                        speckImage: '14_PALADIN_retribution.jpg',
                        addCard: false
                    },
                ]
            },
            {
                classID: 5,
                className: 'Жрец',
                classImage: '5_priest.png',
                classFastIcon: 'Priest.webp',
                classSlogan: 'Жрец - вера в служении',
                specks: [
                    {
                        speckID: 15,
                        speckName: 'Послушание',
                        specProlog: 'Покорное',
                        specSlogan: 'Залог силы',
                        speckDetails: 'Главная задача этих жрецов поддерживать верную им паству, их благое слово вселяет в союзников уверенность и храбрость, а особо отличившимся они даруют свою благодать.',
                        color: '#d5c89f',
                        speckImage: '15_PRIEST_discipline.jpg',
                        addCard: false
                    },
                    {
                        speckID: 16,
                        speckName: 'Свет',
                        specProlog: '',
                        specSlogan: 'Дарует силу',
                        speckDetails: 'Используя свою связь с божественным светом, эти жрецы несут обед - облегчение страданий раненых и больных, уход и лечение всех, кому необходима их помощь.',
                        color: '#d1c661',
                        speckImage: '16_PRIEST_holy.jpg',
                        addCard: false
                    },
                    {
                        speckID: 17,
                        speckName: 'Тьма',
                        specProlog: '',
                        specSlogan: 'Рождает свет',
                        speckDetails: 'Культ этих служителей света не вписывается в общую парадигму, однако они используют силу врага против него же, многим это осуждают, но от союза с такими жрецами никогда не отказываются.',
                        color: '#695e48',
                        speckImage: '17_PRIEST_shadow.jpg',
                        addCard: false
                    },
                ]
            },
            {
                classID: 6,
                className: 'Разбойник',
                classImage: '6_rogue.png',
                classFastIcon: 'Rogue.webp',
                classSlogan: 'Разбойник - скрытый в тенях',
                specks: [
                    {
                        speckID: 18,
                        speckName: 'Ликвидация',
                        specProlog: '',
                        specSlogan: 'Один точный укол',
                        speckDetails: 'Тайные убийцы, используют все известные методы, но больше всего предпочитают силу ядов, в этом им нет равных, очень опасный противник, никакой доспех не защитит от отравленного стилета.',
                        color: '#3e7303',
                        speckImage: '18_ROGUE_assassination.jpg',
                        addCard: false
                    },
                    {
                        speckID: 19,
                        speckName: 'Бой',
                        specProlog: 'Свирепый',
                        specSlogan: 'Настоящая мясорубка',
                        speckDetails: 'Настоящие фанатики больших топоров и мечей, они почти всегда вступают в открытый бой, полагаясь на свою ловкость и проворность, они почти всегда находят самые слабые места своих целей.',
                        color: '#88410d',
                        speckImage: '19_ROGUE_combat.jpg',
                        addCard: false
                    },
                ]
            },
            {
                classID: 7,
                className: 'Шаман',
                classImage: '7_shaman.png',
                classFastIcon: 'Shaman.webp',
                classSlogan: 'Шаман - говорящий с духами',
                specks: [
                    {
                        speckID: 20,
                        speckName: 'Стихии',
                        specProlog: 'Свирепые',
                        specSlogan: 'Покорные служители',
                        speckDetails: 'Посвятив огромное количество времени на бесконечные медитации, эти шаманы получили благословение самих духов первородных стихий, позволив достойным распоряжаться этой силой по своему усмотрению.',
                        color: '#0f3978',
                        speckImage: '20_SHAMAN_elemental.jpg',
                        addCard: false
                    },
                    {
                        speckID: 21,
                        speckName: 'Совершенствование',
                        specProlog: 'Постоянное',
                        specSlogan: 'Своих навыков',
                        speckDetails: 'Великий вождь орды - Тралл был первым, кто обуздал силу грозных духов и вложил ее в свое оружие, теперь же совсем небольшая горстка шаманов практикует это умение, полагаясь лишь на свои собственные силы.',
                        color: '#918265',
                        speckImage: '21_SHAMAN_enhancement.jpg',
                        addCard: false
                    },
                    {
                        speckID: 22,
                        speckName: 'Исцеление',
                        specProlog: 'Духовное',
                        specSlogan: 'Физический покой',
                        speckDetails: 'Духи воды и земли даровали этим шаманам невероятную силу - поддерживать жизнь во всех ее проявлениях, их возможности воистину удивительны, те кто видел эти тайные ритуалы никогда не смогут их забыть.',
                        color: '#7eb1ff',
                        speckImage: '22_SHAMAN_restoration.jpg',
                        addCard: false
                    },
                ]
            },
            {
                classID: 8,
                className: 'Чернокнижник',
                classImage: '8_warlock.png',
                classFastIcon: 'Warlock.webp',
                classSlogan: 'Чернокнижник - адепт запретного культа',
                specks: [
                    {
                        speckID: 23,
                        speckName: 'Колдовство',
                        specProlog: 'Тайное',
                        specSlogan: 'Явные пороки',
                        speckDetails: 'Волшебники, ступившие на темный путь. Их души пленены, все их действия пропитаны смертью и разложением. Вытягивать жизнь из всего живого во имя поддержания своей - главная цель..',
                        color: '#4d6343',
                        speckImage: '23_WARLOCK_affliction.jpg',
                        addCard: false
                    },
                    {
                        speckID: 24,
                        speckName: 'Демонология',
                        specProlog: '',
                        specSlogan: 'Призыв из пустоты',
                        speckDetails: 'Эти культысты способны призывать и подчинять своей воле могущественных существ - обитателей темных измерений. Цена, как всегда, непомерна - часть души хозяина. Коварный кровавый союз.',
                        color: '#744e68',
                        speckImage: '24_WARLOCK_demonology.jpg',
                        addCard: false
                    },
                    {
                        speckID: 25,
                        speckName: 'Разрушение',
                        specProlog: 'Страшное',
                        specSlogan: 'Души',
                        speckDetails: 'Те страшные заклинания, которые используют эти чернокнижники, пропитаны скверной, а некоторые из них, в качестве платы за силу, взымают часть души заклинателя, оставляя на ней раны. Это очень темная магия.',
                        color: '#811c2d',
                        speckImage: '25_WARLOCK_destruction.jpg',
                        addCard: false
                    },
                ]
            },
            {
                classID: 9,
                className: 'Воин',
                classImage: '9_warrior.png',
                classFastIcon: 'Warrior.webp',
                classSlogan: 'Воин - в сражении честь и азарт',
                specks: [
                    {
                        speckID: 26,
                        speckName: 'Оружие',
                        specProlog: 'Холодное',
                        specSlogan: 'Теплая кровь',
                        speckDetails: 'Точные и методичные войны, они всегда знают, чего ищут в сражении и кого хотят устранить первым, прирожденные тактики и стратеги. Они полагаются исключительно на свои топоры и силу, а ее у них много.',
                        color: '#6d572b',
                        speckImage: '26_WARRIOR_arms.jpg',
                        addCard: false
                    },
                    {
                        speckID: 27,
                        speckName: 'Неистовство',
                        specProlog: 'Бурлящее',
                        specSlogan: 'Беги или умри',
                        speckDetails: 'Настоящие берсерки, впадая в боевой транс, они способны сражаться с большими группами даже сильных врагов, как и все войны они любят двуручное оружие, но эти немного больше, поэтому у них их сразу два.',
                        color: '#844b30',
                        speckImage: '27_WARRIOR_fury.jpg',
                        addCard: false
                    },
                    {
                        speckID: 28,
                        speckName: 'Защита',
                        specProlog: 'Агрессивная',
                        specSlogan: 'Сравни нападению',
                        speckDetails: 'Многие войны считают, что полагаться на щит - удел слабых, однако, мало кто готов подтвердить это утверждение в бою с его противником, потому что на практике все оказывается довольно неоднозначно.',
                        color: '#81633c',
                        speckImage: '28_WARRIOR_protection.jpg',
                        addCard: false
                    },
                ]
            },
        ],
    },
    getters: {
        getSelectedSpecks(state) {
            return state.selectedSpeck;
        },
        getSelectedSpecksCount(state) {
            return state.selectedSpeck.length;
        },
        getClasses(state) {
            return state.classes;
        },
        getCostBeginner(state) {
            return state.costBase
        },
        getCostStandard(state) {
            return Math.floor(state.costBase * 0.7334)
        },
        getCostVIP(state) {
            return state.costVIP
        },
        getScrollPosition(state) {
            return state.scrollPosition
        }
    },
    mutations: {
        addSpeck(state, object) {
            object.speck.addCard = true;
            let _tempSpeck = {...object.speck, className: object.className};
            state.selectedSpeck.push(_tempSpeck)
            sessionStorage.setItem('selectedSpeck', JSON.stringify(state.selectedSpeck))
            sessionStorage.setItem('classes', JSON.stringify(state.classes))
        },
        addAllSpeck(state) {
            let containFlag = false
            for (let classWow in state.classes) {
                for (let speck in state.classes[classWow].specks) {
                    state.classes[classWow].specks[speck].addCard = true
                    let pushSpeck = {
                        ...state.classes[classWow].specks[speck],
                        className: state.classes[classWow].className
                    }
                    for (let activeSpeck in state.selectedSpeck) {
                        if (state.selectedSpeck[activeSpeck].speckID === pushSpeck.speckID) {
                            containFlag = true
                            break
                        }
                    }
                    if (!containFlag) {
                        state.selectedSpeck.push(pushSpeck)
                    }
                    containFlag = false
                }
            }
            sessionStorage.setItem('selectedSpeck', JSON.stringify(state.selectedSpeck))
            sessionStorage.setItem('classes', JSON.stringify(state.classes))
        },
        removeSpeck(state, selectSpeck) {
            let index = state.selectedSpeck.indexOf(selectSpeck.valueOf())

            for (let classWow in state.classes) {
                if (state.classes[classWow].className === state.selectedSpeck[index].className)
                    for (let spec in state.classes[classWow].specks) {
                        if (state.classes[classWow].specks[spec].speckID === state.selectedSpeck[index].speckID) {
                            state.classes[classWow].specks[spec].addCard = false;
                        }
                    }
            }
            state.selectedSpeck.splice(index, 1)
            sessionStorage.setItem('selectedSpeck', JSON.stringify(state.selectedSpeck))
            sessionStorage.setItem('classes', JSON.stringify(state.classes))
        },
        removeAllSpecks(state) {
            for (let classWow in state.classes)
                for (let speckClass in state.classes[classWow].specks)
                    state.classes[classWow].specks[speckClass].addCard = false;
            sessionStorage.setItem('selectedSpeck', JSON.stringify(state.selectedSpeck = []))
            sessionStorage.setItem('classes', JSON.stringify(state.classes))
        },
        loadOldSession(state) {
            if (sessionStorage.getItem('selectedSpeck') !== null) {
                state.selectedSpeck = JSON.parse(sessionStorage.getItem('selectedSpeck'))
                state.classes = JSON.parse(sessionStorage.getItem('classes'))
            }
        },
        requestPaymentStatusWhenLoadPage() {
            let PaymentId = sessionStorage.getItem('PaymentId')
            if (PaymentId !== null && PaymentId !== '') {
                axios.post('/api/getPayInfo', {
                    id: PaymentId
                }).then((response) => {
                    if (!response.data) {
                        this.commit('removeAllSpecks');
                        sessionStorage.removeItem('PaymentId');
                    }
                }).catch(function (error) {
                    console.log(error)
                    this.commit('removeAllSpecks');
                })
            }
        },
        setScrollPosition(state, value) {
            state.scrollPosition = value;
        },
        openUrl(state, url) {
            window.open(url, '_blank').location;
        },
        downloadApp(){
            const link = document.createElement('a');
            link.href = "/api/downloadApp";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

    },
});

// export default store;

