import './assets/styles/variables.scss'
import './assets/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import {store} from "../store/store"
import router from './plugins/router'


Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')