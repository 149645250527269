import Vue from 'vue'
import VueRouter from 'vue-router'
import Offer from "@/pages/Offer";
import Politic from "@/pages/Politic";
import Cart from "@/pages/Cart";
import Contacts from "@/pages/Contacts";
import MainPage from "@/pages/MainPage";
import Instruction from "@/pages/Instruction";
import Products from "@/pages/Products";

Vue.use(VueRouter)

export default new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Main',
            component: MainPage
        },
        {
            path: '/contacts',
            name: 'Contacts',
            component: Contacts
        },
        {
            path: '/offer',
            name: 'Offer',
            component: Offer
        },
        {
            path: '/politic',
            name: 'Politic',
            component: Politic
        },
        {
            path: '/cart',
            name: 'Cart',
            component: Cart
        },
        {
            path: '/instruction',
            name: 'Instruction',
            component: Instruction
        },{
            path: '/products',
            name: 'Products',
            component: Products
        },
        {
            path: '*',
            redirect: '/',
            component: MainPage
        }
    ],
    scrollBehavior(to, from,savedPosition) {
        if(savedPosition){
            return savedPosition
        } else {
            return {x:0, y:0}
        }
    }
})