<template>
    <div class="dpskingPolitic">
        <div class="dpskingPoliticContainer">
            <h2>Политика защиты персональной информации пользователей Сайта</h2>
            <p>Настоящая Политика Конфиденциальности предусматривает: при покупке какого-либо товара или услуги на
                Сайте,
                Пользователь указывает
                адрес электронной почты, на которую Администрации необходимо выслать чек операции и ключ персонального
                доступа в
                программное обеспечение, скачанное на Сайте. Данный адрес электронной почты хранится в базе данных для
                решения
                любых спорных, в том числе, но не ограничиваясь, моментов. По окончании срока действия купленного
                товара,
                необходимость в хранении таких данных утрачивается и Администрация Сайта производит их автоматическое и
                полное
                безвозвратное удаление.</p>
            <h2>Предмет политики конфиденциальности</h2>
            <ul>
                <li>Настоящая Политика конфиденциальности устанавливает обязательства Администрации Сайта по
                    неразглашению и
                    обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет
                    по
                    запросу Администрации Сайта при заполнении регистрационной формы, или формы обратной связи.
                </li>
                <li>Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности,
                    предоставляются
                    Пользователем добровольно.
                </li>
            </ul>
            <p>Перечень персональных данных, обрабатываемых Администрацией Сайта:</p>
            <ul>
                <li>адрес электронной почты.</li>
            </ul>
            <p>Также обрабатываются данные, автоматически передающиеся Оператору в процессе посещения и использования
                Сайта с
                помощью установленного на устройстве Пользователя программного обеспечения, в том числе: IP-адрес,
                информация из
                cookie, информация о браузере пользователя (или иной программе, с помощью которой осуществляется доступ
                к
                Сайту), время доступа, адрес запрашиваемой страницы и иные. Оператором могут обрабатываться и иные
                персональные
                данные, непосредственно необходимые для выполнения целей обработки персональных данных и предоставленные
                Пользователем по своей инициативе.</p>

            <h2>Способы и сроки обработки персональной информации</h2>
            <ul>
                <li>Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным
                    способом, в
                    том числе в информационных системах персональных данных с использованием средств автоматизации или
                    без
                    использования таких средств.
                </li>
                <li>При утрате или разглашении персональных данных Администрация Сайта информирует Пользователя об
                    утрате или
                    разглашении персональных данных.
                </li>
                <li>Администрация Сайта принимает необходимые организационные и технические меры для защиты персональной
                    информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения,
                    блокирования,
                    копирования, распространения, а также от иных неправомерных действий третьих лиц.
                </li>
                <li>Администрация Сайта совместно с Пользователем принимает все необходимые меры по предотвращению
                    убытков или
                    иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.
                </li>
            </ul>
            <h2>Обязательства сторон</h2>
            <p>Пользователь обязан:</p>
            <ul>
                <li>предоставить актуальную информацию о персональных данных, необходимую для пользования Сайтом
                    https://dpsking.ru/;
                </li>
                <li>обновить, дополнить предоставленную Администрацией Сайта информацию о персональных данных в случае
                    изменения
                    данной информации.
                </li>
            </ul>
            <p>Администрация Сайта обязана:</p>
            <ul>
                <li>использовать полученную информацию исключительно для целей, указанных в п. 1 настоящей Политики
                    Конфиденциальности;
                </li>
                <li>обеспечить хранение конфиденциальной информации в тайне;</li>
                <li>принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя.</li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "Politic"
}
</script>

<style>
.dpskingPolitic {
    background-color: var(--main-bg-color);
    min-height: 100%;

}

.dpskingPoliticContainer {
    min-width: 300px;
    max-width: 1080px;
    margin: 0 auto;
    padding: 50px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}

.dpskingPoliticContainer > h2 {
    text-align: center;
    color: var(--orange-color);
    font-size: 30px;
    font-family: var(--title-font);
}

.dpskingPoliticContainer > h2:nth-child(n+2) {
    margin-top: 20px;
}

.dpskingPoliticContainer > p, .dpskingPoliticContainer > ul {
    margin-top: 20px;
    color: var(--blue-light-color);
    font-size: 14px;
    font-family: var(--regular-font);
}

.dpskingPoliticContainer > ul > li {
    list-style-type: circle;
    list-style-position: outside;
    display: list-item;
    text-align: justify;
}

.dpskingPoliticContainer > ul > li::marker{
    color: var(--orange-dark-color);
}

.dpskingPoliticContainer > p {
    text-align: justify;
}
</style>