<template>
    <header class="header" @mousemove="moveEffects">
        <div class="lich--img"></div>
        <div class="lich--effect--wrapper">
            <div class="lich--effect">
                <div v-for="index in 300" :key="index" class="effect"></div>
            </div>
        </div>
        <div class="container">
            <div class="header--content">
                <h1 class="header--content__title"><span>Твое</span> <span
                        style="color: #dc9200">удовольствие</span>
                    - твое право</h1>
                <p class="header--content__description">Насладись новыми ощущениями от любимой игры
                    миллионов</p>
                <p class="header--content__comment">новичкам | профессионалам| экспертам</p>
                <div class="header--actions">
                    <button class="header--actions__more" @click="goToMore">Подробнее</button>
                    <button class="header--actions__download" @click="downloadApp">Скачать</button>
                </div>
            </div>

        </div>
    </header>
</template>
<script>
import {mapMutations} from "vuex";

export default {
    name: "Header",
    methods: {
        goToMore() {
            window.scrollTo({
                top: document.querySelector('.product').offsetTop - 70,
                behavior: 'smooth'
            })
        },
        moveEffects(event) {
            let movableEffects = this.$el.querySelector('.lich--effect--wrapper')
            const x = event.clientX * 4 / 200
            const y = event.clientY * 6 / 200
            movableEffects.style.transform = `translateX(${x}px) translateY(${y}px)`
        },
        ...mapMutations({
            downloadApp: "downloadApp",
        })
    }
}
</script>

<style lang="scss">
.header {
    height: 100vh;
    width: 100%;
    background-color: var(--main-bg-color);
    text-transform: uppercase;
    position: relative;
    overflow: hidden;
    z-index: 1;


    a {
        color: var(--blue-light-color);
    }

    font-family: var(--regular-font);
    font-size: 12px;
    font-weight: bold;

    .header--content {
        position: absolute;
        top: 300px;

        &__title {
            font-family: var(--title-font);
            font-weight: normal;
            color: var(--white-color);
            font-size: 48px;
            max-width: 556px;

            span:first-child {
                color: var(--main-bg-color);
                position: relative;
                z-index: 1;

                &::before {
                    position: absolute;
                    content: '';
                    background-color: var(--blue-color);
                    width: 4000px;
                    height: 100%;
                    right: -10px;
                    z-index: -1;
                }
            }
        }

        &__description {
            margin-top: 24px;
            color: var(--blue-light-color);
        }

        &__comment {
            margin-top: 40px;
            font-size: 11px;
            text-transform: none;
            color: var(--orange-color);
        }

        @media (min-height: 1080px) {
            top: 50%;
            transform: translateY(-50%);
        }
        @media (max-width: 600px), (max-height: 400px) {
            top: 50%;
            transform: translateY(-50%);
            &__title {
                font-size: 38px;
            }
            &__description {
                margin-top: 10px;
                font-size: 10px;
            }
            &__comment {
                margin-top: 15px;
            }
        }

        @media (max-width: 500px) {
            position: relative;
            &__title {
                font-size: 28px;
            }
            &__description {
                font-size: 9px;
            }
        }

    }

    .header--actions {
        margin-top: 14px;
        display: flex;
        gap: 18px;


        &__more, &__download {
            height: 50px;
            width: 207px;
            position: relative;
            transition: all 0.3s ease-in-out;
            font-weight: bold;
            z-index: 1;

        }

        @media (max-width: 500px) {
            justify-content: center;
            &__more, &__download {
                height: 50px;
                flex: 1;
            }
        }

        &__more {
            background-color: var(--grey-color);
            color: var(--white-color);

            &:after {
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                transform: scale(1.2, 1.2);
                opacity: 0;
                border: 2px solid var(--orange-color);
                transition: all 0.3s ease-in-out;
                z-index: -1;
            }

            &:hover {
                color: var(--orange-color);
                background-color: var(--main-bg-color);

                &::after {
                    opacity: 1;
                    transform: scale(1, 1);
                }
            }
        }

        &__download {
            &::before {
                background: linear-gradient(var(--orange-color), var(--orange-dark-color));
                position: absolute;
                content: '';
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                z-index: -1;
                transition: all 0.3s ease-in-out;
            }

            &::after {
                position: absolute;
                border: 2px solid var(--orange-color);
                content: '';
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                z-index: -1;
                opacity: 0;
                transform: scale(1.2, 1.2);
                transition: all 0.3s ease-in-out;
            }

            &:hover {
                color: var(--orange-color);

                &::before {
                    opacity: 0;
                }

                &::after {
                    opacity: 1;
                    transform: scale(1, 1);
                }
            }
        }
    }

    .lich--img {
        z-index: -1;
        position: absolute;
        bottom: 0;
        height: 100%;
        width: 100%;
        background-image: linear-gradient(transparent 90%, var(--main-bg-color) 99%),
        url(~@/assets/images/New_Images/Lich.png);
        background-position: bottom center;
        background-size: contain;
        background-repeat: no-repeat;
        @media (max-width: 1920px) {
            background-size: auto 100%;
            background-position: center center;
        }
        @media (max-width: 600px) {
            opacity: 0.8;
        }

    }

    .lich--effect--wrapper {
        position: absolute;
        bottom: 36%;
        right: 36%;
        @media (min-width: 2000px) {
            bottom: 36%;
            right: 39%;
        }
        @media (max-width: 1300px) {
            right: 25%;
        }
        @media (max-width: 1000px) {
            top: 25%;
            right: 50.5%;
        }
    }

    .lich--effect {
        min-height: 10px;
        min-width: 10px;

        $time: 10s;
        $base-hue: 190;

        z-index: -1;
        position: absolute;
        width: 0;
        height: 0;
        transform-style: preserve-3d;
        //perspective: 500px;
        animation: rotate $time infinite linear;
        transform-origin: center center;

        @media (min-width: 1000px) {
            $total: 300;
            $orb-size: 140px;
            $particle-size: 2px;
            .effect {
                position: absolute;
                width: $particle-size;
                height: $particle-size;
                border-radius: 50%;
                opacity: 0;
            }

            @for $i from 1 through $total {
                $z: (random(360) * 1deg); // random angle to rotateZ
                $y: (random(360) * 1deg); // random to rotateX
                $hue: ((10/$total * $i) + $base-hue); // set hue

                .effect:nth-child(#{$i}) { // grab the nth particle
                    animation: orbit#{$i} $time infinite;
                    animation-delay: ($i * .02s);
                    background-color: hsla($hue, 100%, 50%, 1);
                }

                @keyframes orbit#{$i}{
                    20% {
                        opacity: 1; // fade in
                    }
                    30% {
                        transform: rotateZ(-$z) rotateY($y) translateX($orb-size) rotateZ($z); // form orb
                    }
                    80% {
                        transform: rotateZ(-$z) rotateY($y) translateX($orb-size * 2) rotateZ($z); // hold orb state 30-80
                        opacity: 1; // hold opacity 20-80
                    }
                    100% {
                        transform: rotateZ(-$z) rotateY($y) translateX(($orb-size * 4)) rotateZ($z); // translateX * 3
                    }
                }
            }
        }


        @media (max-width: 1000px), (max-device-height: 400px) {
            $total: 70;
            $orb-size: 60px;
            $particle-size: 2px;
            .effect {
                position: absolute;
                width: $particle-size;
                height: $particle-size;
                border-radius: 50%;
                opacity: 0;
            }

            @for $i from 1 through $total {
                $z: (random(360) * 1deg); // random angle to rotateZ
                $y: (random(360) * 1deg); // random to rotateX
                $hue: ((10/$total * $i) + $base-hue); // set hue

                .effect:nth-child(#{$i}) { // grab the nth particle
                    animation: orbit#{$i} $time infinite;
                    animation-delay: ($i * .02s);
                    background-color: hsla($hue, 100%, 50%, 1);
                }

                @keyframes orbit#{$i}{
                    20% {
                        opacity: 1; // fade in
                    }
                    30% {
                        transform: rotateZ(-$z) rotateY($y) translateX($orb-size) rotateZ($z); // form orb
                    }
                    80% {
                        transform: rotateZ(-$z) rotateY($y) translateX($orb-size * 2) rotateZ($z); // hold orb state 30-80
                        opacity: 1; // hold opacity 20-80
                    }
                    100% {
                        transform: rotateZ(-$z) rotateY($y) translateX(($orb-size * 4)) rotateZ($z); // translateX * 3
                    }
                }
            }
        }


        @media (max-height: 950px) {
            $total: 190;
            $orb-size: 40px;
            $particle-size: 2px;
            .effect {
                position: absolute;
                width: $particle-size;
                height: $particle-size;
                border-radius: 50%;
                opacity: 0;
            }

            @for $i from 1 through $total {
                $z: (random(360) * 1deg); // random angle to rotateZ
                $y: (random(360) * 1deg); // random to rotateX
                $hue: ((10/$total * $i) + $base-hue); // set hue

                .effect:nth-child(#{$i}) { // grab the nth particle
                    animation: orbit#{$i} $time infinite;
                    animation-delay: ($i * .02s);
                    background-color: hsla($hue, 100%, 50%, 1);
                }

                @keyframes orbit#{$i}{
                    20% {
                        opacity: 1; // fade in
                    }
                    30% {
                        transform: rotateZ(-$z) rotateY($y) translateX($orb-size) rotateZ($z); // form orb
                    }
                    80% {
                        transform: rotateZ(-$z) rotateY($y) translateX($orb-size * 2) rotateZ($z); // hold orb state 30-80
                        opacity: 1; // hold opacity 20-80
                    }
                    100% {
                        transform: rotateZ(-$z) rotateY($y) translateX(($orb-size * 4)) rotateZ($z); // translateX * 3
                    }
                }
            }
        }


        @keyframes rotate {
            100% {
                transform: rotateY(360deg) rotateX(360deg);
            }
        }

    }

}

</style>