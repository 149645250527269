<template>
    <div class="router-wrapper" id="app">
        <router-view/>
    </div>
</template>

<script>

import {mapMutations} from 'vuex'

export default {
    name: 'App',
    methods: {
        ...mapMutations([
            "loadOldSession",
            "requestPaymentStatusWhenLoadPage",
        ]),
        sitemap() {
            const routes = this.$router
                    .getRoutes()
                    .map(r => r.path)
                    .filter(r => !r.includes(':')) // removes routes with params
                    .map(r => `<url><loc>https://dpsking.ru${r}</loc></url>`)
            console.log(`
    <?xml version='1.0' encoding='UTF-8'?>
    <urlset xmlns='http://www.sitemaps.org/schemas/sitemap/0.9'>
        ${routes.join('\n')}
    </urlset>
  `)
        }
    },

    created() {
        this.requestPaymentStatusWhenLoadPage();
        this.loadOldSession();
    }
};
</script>

<style>
.router-wrapper {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: #2f2d2f var(--main-bg-color);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 6px;
}

*::-webkit-scrollbar-track {
    background: var(--main-bg-color);
}

*::-webkit-scrollbar-thumb {
    background-color: #2f2d2f;
    border-radius: 14px;
    border: 1px solid var(--main-bg-color);
}
</style>
