<template>
    <div class="mc">
        <HeaderMenu/>
        <section class="instruction">
            <div class="container">
                <div class="instruction--header--container">
                    <div class="title"><h1>DPS King<br><span>повеливай рекаунтом</span></h1></div>
                </div>
                <div class="programSection">
                    <div class="programSection--column1">
                        <h3 class="programSection--title">Все Что Нужно Королю</h3>
                        <p class="programSection--description">Интерфейс ассистента был разработан в стремлении достичь
                            две цели - удобство и красота. Наша команда приложила максимум усилий, чтобы порадовать
                            пользователей.</p>
                        <div class="image1">
                            <img src="@/assets/images/New_Images/InstructionPage/Lich.jpg">
                        </div>
                    </div>
                    <div class="programSection--column2">
                        <div class="image2">
                            <img src="@/assets/images/New_Images/InstructionPage/MainPage.png" alt="">
                        </div>
                        <h3 class="programSection--title">И Немного Больше</h3>
                        <p class="programSection--description">Самое главное - это то, что цель была достигнута, и
                            сейчас любой может скачать наш продукт и воспользоваться им по назначению</p>
                    </div>
                </div>
                <div class="buySection">
                    <h2>Дорога в личные владения</h2>
                    <div class="buySection--content">
                        <div class="image1">
                            <img src="@/assets/images/New_Images/InstructionPage/Guide.png" alt="">
                        </div>
                        <div class="content--text">
                            <h2 class="title">Все проще чем кажется</h2>
                            <p class="description">Скачай приложение, разархивируй в любую удобную папку и запусити его.
                                На вкладке с настройкой ты увидешь руководство, которое подробным образом описывает
                                механизм настройки ассистента.</p>
                            <p class="description">Но перед этим тебе конечно же надо авторизироваться в системе и
                                настроить клиент под свои нужны. Это сделать очень легко. Нет никаких логинов и
                                постоянных вводов паролей - ассистент будет работать ТОЛЬКО с твоим компьютером.</p>

                        </div>
                        <div class="login--images--content">
                            <div class="image1">
                                <img src="@/assets/images/New_Images/InstructionPage/Login.png" alt="">
                            </div>
                            <div class="image2">
                                <img src="@/assets/images/New_Images/InstructionPage/PlayPage.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="howUseSection">
                    <h2 class="title">Личная сокровищница</h2>
                    <div class="howUseSection--content">
                        <div class="comments">
                            <p>Чтобы насладиться всем спектром ощущений, которые может подарить тебе ассистент
                                достаточно вего лишь попробовать и ты не сможешь отказаться, ведь с нами ты не будешь
                                забанен.</p>
                            <p>Ассистент не встраивается в оригинальный клиент игры, а работает как аддон, в папке с
                                твоими штатными аддонами, ни один античит не увидит наличие подозрительного софта на
                                твоем компьютере.</p>
                            <p>Нам известно о случаях внесения ассистента в карантин некоторыми видами антивирусов.
                                Данное явление вызвано тем, что в программе происходит считывание хоткеев, которые могут
                                быть установлены в настройках программы, никаких криминальных действий в программе не
                                совершается, мы дорожим своей аудиторией. Вы можете сами в этом убедиться
                                воспользовавшись сервисом VirusTotal.</p>
                        </div>
                        <div class="image">
                            <img src="@/assets/images/New_Images/Logo.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
        <UpArrow/>
    </div>
</template>

<script>
import HeaderMenu from "@/components/HeaderMenu";
import Footer from "@/components/Footer";
import UpArrow from "@/components/UpArrow";

export default {
    components: {UpArrow, Footer, HeaderMenu}
}
</script>

<style lang="scss">
.instruction {
    background-color: var(--main-bg-color);

    .instruction--header--container {
        margin-top: 90px;
        height: 500px;
        background-image: linear-gradient(130deg, rgba(1, 11, 25, 0.95) 5%, rgba(0, 0, 0, 0) 50%),
        linear-gradient(75deg, rgba(1, 11, 25, 0.95) 5%, rgba(0, 0, 0, 0) 50%),
        url(~@/assets/images/New_Images/InstructionPage/HeaderLich.jpg);
        background-repeat: no-repeat;
        background-size: 1330px;
        background-position: center top;
        position: relative;

        .title {
            font-family: var(--title-font);
            font-size: 48px;
            bottom: -38px;
            position: absolute;
            line-height: 0.9;
            color: var(--orange-color);

            span {
                color: var(--blue-color)
            }
        }
    }

    .programSection {
        margin-top: 100px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-family: var(--regular-font);
        gap: 50px;

        h3 {
            font-size: 27px;
            color: var(--blue-light-color);
        }

        p {
            margin-top: 20px;
            font-size: 14px;
            color: var(--white-color);
        }

        .programSection--column1 {
            display: flex;
            margin-top: 80px;
            flex-direction: column;

            p {
                width: 400px;
            }

            .image1 {
                margin-top: 70px;
                height: 400px;
                width: 300px;
                position: relative;
                overflow: hidden;

                img {
                    max-width: 100%;
                    object-position: 0 -32px;
                    opacity: 0.7;
                }

                &::after {
                    position: absolute;
                    content: '';
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                    background-image: linear-gradient(to top right, var(--main-bg-color) 5%, transparent 40%);
                }
            }
        }

        .programSection--column2 {
            display: flex;
            flex-direction: column;
            text-align: start;
            flex-grow: 1;
            min-width: 840px;

            p {
                max-width: 300px;
            }

            .image2 {
                position: relative;
                height: 100%;

                img {
                    opacity: 0.8;
                }

                &::after {
                    position: absolute;
                    content: '';
                    height: 100%;
                    width: 100%;
                    inset: 0;
                    background-image: linear-gradient(to top left, var(--main-bg-color) 8%, transparent 40%);
                    background-size: 100%;
                    background-repeat: no-repeat;
                    background-position: top;
                }
            }

        }
    }

    .buySection {
        margin-top: 150px;

        h2 {
            font-family: var(--title-font);
            font-size: 48px;
            color: var(--orange-color);
        }

        .buySection--content {
            margin-top: 30px;
            position: relative;

            .image1 {
                max-width: 900px;
                position: relative;

                img {
                    opacity: 0.8;
                }

                &::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background-image: linear-gradient(to top left, var(--main-bg-color) 1%, transparent 40%),
                    linear-gradient(to top right, var(--main-bg-color) 1%, transparent 40%),
                    linear-gradient(to top, var(--main-bg-color) 1%, transparent 40%);
                    opacity: 0.7;
                }
            }

            .content--text {
                position: absolute;
                width: 47%;
                top: 120px;
                right: 0;
                background-color: var(--main-bg-color);
                opacity: 0.9;
                padding: 20px 20px 50px 20px;

                .title {
                    font-family: var(--title-font);
                    color: var(--blue-color);
                    font-size: 30px;
                    text-align: center;
                }

                .description {
                    margin-top: 30px;
                    font-family: var(--regular-font);
                    color: var(--white-color);
                }
            }


        }

        .login--images--content {
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            margin-top: -100px;

            .image1 {
                position: relative;
                grid-column: 10/13;

                &::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background-image: linear-gradient(to top, var(--main-bg-color) 1%, transparent 40%);
                    opacity: 0.8;
                }

                img {
                    position: relative;
                    opacity: 0.8;
                }
            }

            .image2 {
                grid-column: 1/10;
                grid-row: 1;
                margin-top: 190px;
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background-image: linear-gradient(to top, var(--main-bg-color) 1%, transparent 40%);
                    opacity: 0.8;
                }
            }
        }

    }

    .howUseSection {
        margin-top: 100px;

        .title {
            font-family: var(--title-font);
            font-size: 48px;
            text-align: end;
            color: var(--blue-color);
        }

        .howUseSection--content {
            display: flex;
            justify-content: space-between;

            .comments {
                margin-top: 40px;
                font-family: var(--regular-font);
                color: var(--white-color);

                p {
                    padding: 30px 30px 30px 0;
                    max-width: 700px;
                    border-bottom: 1px solid var(--blue-light-color);
                    transition: all .3s ease-out;

                    &:hover {
                        color: var(--orange-color);
                    }
                }
            }

            .image {
                //margin-top: 50px;
                width: 400px;
            }
        }
    }

    @media (max-width: 1315px) {
        .programSection {
            .programSection--column1 {
                margin-top: 0;
                flex-shrink: 1;

                .programSection--description {
                    width: unset;
                    max-width: 400px;
                }

                .image1 {
                    width: unset;
                    height: unset;
                }
            }

            .programSection--column2 {
                min-width: unset;
                flex-shrink: 1;

                .image2 {
                    height: unset;
                }
            }
        }
    }
    @media (max-width: 1100px) {
        .buySection {
            margin-top: 80px;

            .content--text {
                font-size: 12px;

                .title {
                    font-size: 18px !important;
                }
            }
        }
    }
    @media (max-width: 900px) {
        .instruction--header--container {
            margin-top: 75px;

            .title {
                font-size: 30px;
                bottom: -15px;
            }
        }
        .programSection {
            margin-top: 50px;
            gap: 10px;

            h3 {
                font-size: 18px;
            }

            p {
                font-size: 12px;
            }

            .programSection--column1 {
                margin-top: 0;
                flex-shrink: 1;

                .programSection--description {
                    width: unset;
                    max-width: 400px;
                }

                .image1 {
                    width: unset;
                    height: unset;
                }
            }

            .programSection--column2 {
                min-width: unset;
                flex-shrink: 1;

                .image2 {
                    height: unset;
                }
            }
        }
        .buySection {
            margin-top: 20px;

            h2 {
                font-size: 30px;
            }

            .buySection--content {
                .content--text {
                    padding: 10px 0;
                    max-width: 100%;
                    width: unset;

                    .description {
                        margin-top: 20px;
                    }

                }

                .login--images--content {
                    margin-top: -50px;

                    .image2 {
                        margin-top: 50px;
                    }
                }
            }
        }
        .howUseSection {
            margin-top: 50px;

            .title {
                font-size: 30px;
            }

            .howUseSection--content {
                .comments {
                    margin-top: 20px;

                    p {
                        padding: 15px 15px 15px 0;
                        font-size: 12px;
                    }
                }

                .image {
                    width: unset;
                }
            }
        }
    }
    @media (max-width: 600px) {
        .instruction--header--container {
            margin-top: 65px;
            height: 400px;
        }
        .programSection {
            flex-direction: column;
            justify-content: start;
            align-items: start;

            .programSection--column1 {
                margin-top: 0;
                flex-shrink: 1;

                .programSection--description {
                    width: unset;
                    max-width: 400px;
                }

                .image1 {
                    margin-top: 10px;
                    display: flex;
                    justify-content: center;
                }
            }

            .programSection--column2 {
                margin-top: -20px;
                min-width: unset;
                flex-shrink: 1;

                .image2 {
                    height: unset;
                }

                .programSection--title, .programSection--description {
                    align-self: end;
                    text-align: end;
                }
            }
        }
        .buySection {
            margin-top: 20px;

            h2 {
                font-size: 30px;
            }

            .buySection--content {
                .content--text {
                    position: relative;
                    //margin-top: 0;
                    top: 0;
                }

                .login--images--content {
                    margin-top: 20px;
                    grid-template-columns: unset;
                    grid-template-rows: 290px 1fr;
                    justify-items: center;

                    .image1 {
                        grid-row: 1;
                        grid-column: unset;
                        max-width: 160px;
                    }

                    .image2 {
                        margin-top: 0;
                        grid-row: 2;
                        grid-column: unset;
                    }
                }
            }
        }
        .howUseSection {
            margin-top: 50px;

            .title {
                font-size: 30px;
            }

            .howUseSection--content {
                position: relative;
                z-index: 2;
                .comments {
                    margin-top: 20px;

                    p {
                        padding: 15px 15px 15px 0;
                        font-size: 12px;
                    }
                }

                .image {
                    position: absolute;
                    width: 250px;
                    bottom: 10%;
                    right: 50%;
                    transform: translateX(50%);
                    z-index: -1;
                    opacity: 0.2;
                }
            }
        }
    }
}

</style>