<template>
    <section class="tariff--sec">
        <div class="container">
            <div class="content">
                <div class="tariff--title">
                    <h2>Выбери свой <span>путь</span></h2>
                </div>
                <div class="tariff--description"><p>Не сомневайся в том</p>
                    <p>чего всегда ЖАЖДАЛ</p></div>
                <div class="tariff--products">
                    <div class="tariff--products__start product--anim">
                        <div class="product--anim--bg product--anim__start"></div>
                        <div class="start--product card--product">
                            <div class="title">
                                <h2>START</h2>
                            </div>
                            <div class="tariff">
                                <ul class="tariff--list">
                                    <li class="tariff--list__item">1 спек</li>
                                    <li class="tariff--list__item">Доступ в DC</li>
                                </ul>
                            </div>
                            <div class="description">
                                <span class="firstline">Рыцарь ищущий свой путь</span>
                                <span class="secondline">Один спек на месяц</span>
                                <span class="thirstline">отличный способ начать</span>
                            </div>
                            <div class="cost">
                                <h2>{{ costBeginner }} р.</h2>
                            </div>
                        </div>
                    </div>
                    <div class="tariff--products__medium product--anim">
                        <div class="product--anim--bg product--anim__medium"></div>
                        <div class="medium--product card--product">
                            <div class="title">
                                <h2>MEDIUM</h2>
                            </div>
                            <div class="tariff">
                                <ul class="tariff--list">
                                    <li class="tariff--list__item">От 3 до 5 спеков</li>
                                    <li class="tariff--list__item">Доступ в DC</li>
                                    <li class="tariff--list__item">Приятные бонусы для продления</li>
                                </ul>
                            </div>
                            <div class="description">
                                <span class="firstline">Ты всегда хотел это попробовать</span>
                                <span class="secondline">От 3 до 5 спеков на месяц</span>
                                <span class="thirstline">все в одном пакете</span>
                            </div>
                            <div class="cost">
                                <h2>{{ Math.floor(costStandard * 3) }} Р.</h2>
                            </div>
                        </div>
                    </div>
                    <div class="tariff--products__vip product--anim">
                        <div class="product--anim--bg product--anim__vip"></div>
                        <div class="vip--product card--product">
                            <div class="title">
                                <h2>VIP</h2>
                            </div>
                            <div class="tariff">
                                <ul class="tariff--list">
                                    <li class="tariff--list__item">29 доступных спеков</li>
                                    <li class="tariff--list__item">Доступ в PRIVATE DC</li>
                                    <li class="tariff--list__item">Полное сопровождение</li>
                                    <li class="tariff--list__item">Месяц без ограничений</li>
                                </ul>
                            </div>
                            <div class="description">
                                <span class="firstline">Пакет услуг истинного короля</span>
                                <span class="secondline">Полный доступ ко всем</span>
                                <span class="thirstline">возможностям программы</span>
                            </div>
                            <div class="cost">
                                <h2>{{ costVip }} Р.</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "Tariff",
    computed: {
        ...mapGetters({
            costBeginner: 'getCostBeginner',
            costStandard: 'getCostStandard',
            costVip: 'getCostVIP',
        }),
    }
}
</script>

<style lang="scss">

.tariff--sec {
    background-color: var(--main-bg-color);
    overflow: hidden;

    .content {
        min-height: 800px;
        position: relative;
        display: flex;
        flex-direction: column;
        z-index: 1;

        &::after {
            position: absolute;
            content: '';
            left: 30%;
            top: 35%;
            height: 60px;
            width: 2000px;
            background-color: var(--blue-color);
            z-index: -1;
        }

        .tariff--title {
            font-family: var(--title-font);
            font-size: 48px;
            color: var(--blue-color);
            margin-top: 80px;
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            gap: 15px;

            h2 {
                grid-column: 7/12;
            }

            span {
                color: var(--orange-color);
            }
        ;
        }

        .tariff--description {
            color: var(--orange-color);
            font-family: var(--regular-font);
            font-size: 18px;
            margin-top: 70px;

            p:nth-child(2) {
                margin-left: 50px;
            }

            flex-grow: 1;
        }

        .tariff--products {
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            column-gap: 15px;
            overflow: hidden;
            padding-bottom: 20px;
            position: relative;
            align-items: end;

            &__start {
                grid-column: 1/4;
                height: 440px;

                .start--product, &::before {
                    height: 480px;
                }

                &::before {
                    background-color: var(--grey-light-color);
                }
            }

            &__medium {
                grid-column: 4/7;
                height: 470px;

                .medium--product, &::before {
                    height: 510px;
                }

                &::before {
                    background-color: var(--blue-light-color);
                }

            }

            &__vip {
                grid-column: 7/13;
                height: 500px;

                .vip--product, &::before {
                    height: 540px;
                }

                &::before {
                    background-color: var(--orange-color);
                }
            }

            .product--anim {
                position: relative;
                overflow: hidden;
                z-index: 1;

                .start--product, .medium--product, .vip--product, &::before {
                    position: absolute;
                    bottom: -100%;
                    width: 100%;
                    transition: all 0.3s ease-in-out;

                }

                &::before {
                    content: '';
                    z-index: -1;
                }

                &:hover {
                    .start--product, .medium--product, .vip--product, &::before {
                        bottom: 0;
                    }

                    .product--anim--bg {
                        opacity: 48%;
                    }
                }

                .product--anim--bg {
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    top: 0;
                    background-repeat: no-repeat;
                    transition: all 0.3s ease-in-out;
                }

                &__start {
                    background-image: url(~@/assets/images/New_Images/start.png);
                    background-size: 70%;
                    background-position: center 0;
                }

                &__medium {
                    background-image: url(~@/assets/images/New_Images/medium.png);
                    background-size: 70%;
                    background-position: center 30px;
                }

                &__vip {
                    background-image: url(~@/assets/images/New_Images/vip.png);
                    background-size: 45%;
                    background-position: center 60px;
                }

            }


        }

        .card--product {
            display: flex;
            flex-direction: column;

            .title {
                margin-top: 50px;
                font-family: var(--title-font);
                font-size: 48px;
                color: var(--orange-color);
            }

            .tariff {
                font-family: var(--regular-font);
                font-size: 16px;
                color: var(--white-color);
                padding: 10px;

                .tariff--list {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                }
            }

            .description {
                font-family: var(--regular-font);
                font-size: 12px;
                font-weight: bold;
                display: flex;
                flex-direction: column;
                align-items: start;

                .firstline {
                    font-size: 16px;
                }

                .thirstline {
                    align-self: center;
                }
            }

            .cost {
                font-family: var(--title-font);
                font-size: 48px;
            }
        }

        .vip--product {
            .title {
                margin-left: 80px;
                color: var(--main-bg-color);
            }

            .tariff {
                margin-top: 65px;
                align-self: flex-end;
                background-color: var(--main-bg-color);
            }

            .description {
                flex: 1;
                justify-content: flex-end;
                max-width: 285px;
                margin-left: 20px;
            }

            .cost {
                align-self: center;
                margin-top: 25px;
            }
        }

        .medium--product {
            .title {
                align-self: center;
            }

            .tariff {
                background-color: var(--orange-color);
                max-width: 180px;
                margin-top: 40px;
            }

            .description {
                color: var(--orange-color);
                justify-content: flex-end;
                align-self: center;
                flex: 1;

                .firstline {
                    letter-spacing: -1px;
                }
            }

            .cost {
                align-self: center;
                margin-top: 25px;
                color: var(--orange-color);
            }
        }

        .start--product {
            .title {
                align-self: end;
                margin-right: 25px;
                color: var(--blue-color);
            }

            .tariff {
                background-color: var(--blue-color);
                max-width: 125px;
                color: var(--main-bg-color);
                margin-top: 60px;
            }

            .description {
                align-self: center;
                justify-content: end;
                flex: 1;
                color: var(--blue-color);
            }

            .cost {
                align-self: center;
                color: var(--blue-color);
                margin-top: 25px;
            }
        }

        @media (max-width: 1150px), (orientation: landscape) and (max-device-height: 700px) {
            .product--anim {
                .start--product, .medium--product, .vip--product, &::before {
                    bottom: 0 !important;
                }

                .product--anim--bg {
                    opacity: 48%;
                }
            }
        }

        @media (max-width: 890px) {
            min-height: 600px;
            .tariff--description {
                font-size: 14px;
            }
            .tariff--title {
                margin-top: 40px;
                font-size: 30px;
            }
            .tariff--description {
                margin-top: 10px;
            }
            .product--anim {
                h2 {
                    font-size: 30px;
                }

                .description .firstline {
                    font-size: 12px;
                }

                span {
                    font-size: 10px;
                }
            }

            .tariff--products__start {
                $height: 350px;

                .start--product {
                    height: $height;
                }

                .product--anim__start {
                    height: $height;
                }

                height: $height;
            }
            .tariff--products__medium {
                $height: 380px;

                .medium--product {
                    height: $height;
                }

                .product--anim__medium {
                    height: $height;
                }

                height: $height;
            }
            .tariff--products__vip {
                $height: 410px;

                .vip--product {
                    height: $height;
                }

                .product--anim__vip {
                    height: $height;
                }

                height: $height;
            }
            .card--product .tariff {
                margin-top: 7%;
                .tariff--list {
                    gap: 2px;
                    font-size: 13px;
                }
            }
            .description{
                justify-content: center !important;
            }
            .tariff--description{
                flex: 0.4;
            }
        }

        @media (max-width: 600px){
            .tariff--products{
                grid-template-columns: unset;
                column-gap: unset;
                grid-template-rows: repeat(3, 1fr);
                row-gap: 20px;
                margin-top: 10px;
                .product--anim{
                    grid-column: unset;
                    $height: 300px;
                    height: $height !important;
                    .product--anim--bg{
                        height: $height !important;
                        background-size: 40%;
                    }
                    .card--product{
                        height: $height !important;
                        .title{
                            margin: 20px auto;
                        }
                    }
                    .start--product {
                        .description, .description > * {
                            align-self: end;
                            padding-right: 10px;
                        }
                    }
                }
            }
        }
    }

}

</style>