<template>
    <div class="dpskingOfer">
        <div class="dpskingOferContainer">
            <h2>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</h2>
            <p>Настоящим Администрация сайта https://dpsking.ru/ (далее - Администрация), предлагает любому исключительно
                дееспособному
                физическому лицу (далее - Пользователь), использовать услуги, предоставляемые на сайте https://dpsking.ru/
                (далее - Сайт) на условиях настоящего пользовательского соглашения.
                Настоящий Договор является публичной офертой в соответствии со ст. 437 Гражданского кодекса Российской
                Федерации,
                т.е. содержит все существенные условия соглашения, заключаемого между Администрацией и Пользователем.
                Акцепт оферты осуществляется действиями Пользователя, свидетельствующими об акцепте (конклюдентные
                действия), в
                том числе, но не ограничиваясь, посредством скачивания, использования Сайта и связанных с ним страниц в
                сети
                «Интернет», скачивания программного обеспечения (далее - ПО), размещенного на Сайте,
                любым иным способом, свидетельствующим о желании Пользователя воспользоваться услугами Администрации. В
                случае
                акцепта настоящей оферты, между сторонами заключается договор на основании п.2 ст. 434 и п. 3 ст. 438
                Гражданского
                кодекса Российской Федерации.
                Договор в электронной форме по юридической силе приравнивается к договору, составленному в письменной
                форме и
                подписанному Сторонами, в том числе удостоверенному собственноручными подписями Сторон.</p>
            <p><strong>Учитывая важность вышеизложенного, Пользователю настоятельно рекомендуется внимательно
                ознакомиться с
                содержанием
                настоящего пользовательского соглашения, а также приложениями к нему (при их наличии) и дополнительными
                документами (Правила Сайта), регламентирующими деятельность Сайта, и в случае несогласия с каким-либо
                положением указанных документов, предлагается отказаться от покупки использования услуг, предоставляемых
                Администрацией.</strong></p>
            <h2>Термины и определения</h2>
            <ul>
                <li><strong>Сайт</strong> – это идентифицированное место в сети Интернет (виртуальном пространстве), в
                    котором
                    располагается
                    информация, оформленная и структурированная определенным образом. Для целей настоящей Оферты Сайтом
                    признается
                    сайт, расположенный в сети «Интернет» по адресу: https://dpsking.ru/.
                </li>
                <li><strong>Пользователь</strong> – физическое дееспособное лицо, которое акцептовало настоящее
                    Соглашение.
                    Пользователем может быть только лицо старше 18 лет или имеющее полную дееспособность в соответствии
                    с
                    Гражданским кодексом Российской Федерации. В случае выявления Администрацией факта того, что
                    Пользователю
                    менее 18 лет или он не имеет полную дееспособность в соответствии с Гражданским кодексом Российской
                    Федерации,
                    Администрация вправе приостановить оказание услуг, заблокировать Аккаунт, а также применять иные
                    меры
                    ответственности, установленные настоящим соглашением.
                </li>
                <li><strong>Аккаунт</strong> – учетная запись Пользователя, необходимая для использования ПО. Для
                    создания
                    Аккаунта Пользователь
                    осуществляет покупку любого товара на Сайте в разделе "ПОДПИСКА". Каждому аккаунту присваивается
                    динамический идентификационный номер (ID) и ключ (KEY).
                </li>
                <li><strong>Услуги</strong> – предоставляемые Администрацией в рамках Сайта. Перечень услуг, которые
                    предоставляются
                    Администрацией
                    полностью указан в разделе 2 Соглашения, все иные услуги предоставляются третьими лицами на
                    условиях,
                    определенных
                    такими третьими лицами.
                </li>
                <li><strong>Соглашение</strong> – настоящее пользовательское соглашение, которое содержит перечень норм
                    и
                    правил, на основании
                    которых
                    функционирует Сайт, а также происходят все процессы взаимоотношений между Администрацией и
                    Пользователем.
                </li>
                <li><strong>Сторона</strong> – любая из сторон Соглашения, а Стороны при упоминании обеих Сторон.</li>

            </ul>
            <p>В настоящем Соглашении могут быть использованы термины, неопределенные в настоящем разделе «Термины и
                определения». В случае отсутствия однозначного толкования термина в тексте настоящего соглашения
                используется
                толкование, определенное в игровом сообществе в сети «Интернет» в аналогичных проектах.</p>
            <h2>Предмет Соглашения</h2>
            <p>В рамках настоящего Соглашения Администрация осуществляет предоставление Пользователю услуг по
                использованию Сайта. В состав таких услуг входит следующее:
                Предоставление возможности использовать сайт https://dpsking.ru/, включая его просмотр, создание Аккаунта
                и
                использование Аккаунта в иных сервисах в сети «Интернет»;
                Скачивание ПО, размещенного на сайте https://dpsking.ru/. Для
                использования такого ПО Администрация предоставляет Пользователю простую (неисключительную)
                лицензию, действующую в течение всего срока настоящего Соглашения.</p>
            <p><strong>Условия использования такого ПО устанавливаются их правообладателем. Претензии и вопросы по
                поводу использования данного ПО надлежит направлять напрямую их правообладателям и
                уполномоченным лицам. Администрация сайта таким лицом не является и данных о этих лицах не имеет.
                Администрация
                осуществляет только информационное и техническое содействие (обслуживает Сайт и сервер баз данных) для
                возможности
                функционирования данного ПО.</strong></p>
            <h2>Права и обязанности Сторон</h2>
            <p>Пользователь обязуется:</p>
            <ul>
                <li>Соблюдать положения Соглашения;</li>
                <li>Не предоставлять доступ другим Пользователям к собственному аккаунту, если это может привести к
                    нарушению
                    Соглашения. Пользователь самостоятельно отвечает за сохранность своего Аккаунта, а также
                    имущества, благ, закрепленного за ним;
                </li>
                <li>Ознакомиться с Соглашением до момента его акцепта. Регистрация Аккаунта означает полное и
                    безоговорочное
                    принятие
                    Пользователем условий Соглашения в соответствии с нормами действующего законодательства Российской
                    Федерации;
                </li>
                <li>Не реже, чем раз в каждые 14 дней знакомиться с условиями Соглашения, а также самостоятельно
                    отслеживать вносимые в них изменения;
                </li>
                <li>Не использовать какое-либо оборудование, устройства, программное обеспечение или иные способы с
                    целью
                    вмешательства в деятельность Администрации в любой форме в процессе оказания услуг;
                </li>
                <li>Использовать функционал предоставляемого Сайта только по его прямому назначению;</li>
                <li>Выполнять иные обязательства, предусмотренные Соглашением.</li>
            </ul>
            <p>Пользователь вправе:</p>
            <ul>
                <li>Требовать от Администрации надлежащего выполнения условий Соглашения;</li>
                <li>Использовать предоставленные ему услуги в течение всего срока их действия;</li>
                <li>Направлять Администрации предложения об улучшении качества Сайта, услуг, дополнительных
                    возможностей;
                </li>
                <li>Осуществлять иные права, предусмотренные Соглашением.</li>
            </ul>
            <p>Администрация обязуется:</p>
            <ul>
                <li>Качественно и в полном объеме оказывать услуги в соответствии с условиями Соглашения.</li>
                <li>Поддерживать доступность Сайта и его надлежащее функционирование в течение всего срока действия
                    Соглашения,
                    но
                    с
                    учетом возможных сбоев, технических ограничений в работе и т.д., указанных в Соглашении.
                </li>
                <li>Оказывать услуги методами, незапрещенными действующим законодательством Российской Федерации.</li>
            </ul>
            <p>Администрация имеет право:</p>
            <ul>
                <li>Приостановить оказание услуг в случае наличия технических неполадок или иных уважительных причин,
                    определенных
                    по
                    усмотрению Администрации.
                </li>
                <li>Требовать от Пользователя соблюдения Соглашения.</li>
                <li>Применять меры ответственности в отношении Пользователей, нарушающих Соглашение.</li>
                <li>В любое время изменять оформление Сайта, его контент, изменять или дополнять используемые, или
                    хранящиеся на
                    Сайте
                    ПО и другие объекты, любые серверные приложения, в любое время с предварительным
                    уведомлением Пользователя или без такового.
                </li>
                <li>При необходимости отправлять Пользователю по электронной почте и другими доступными способами
                    сообщения,
                    касающиеся Сайта.
                </li>
                <li>Устанавливать дополнительные ограничения в рамках Сайта, а также изменять такие ограничения в любое
                    время.
                </li>
            </ul>
            <h2>Порядок вступления в силу, срок действия, изменения и прекращения Соглашения</h2>
            <p>Соглашение вступает в силу с момента принятия ее условий Пользователем – путем акцепта, в порядке,
                установленном
                Соглашением.
                Настоящее Соглашение действует неопределенный срок.
                Любая из Сторон вправе в одностороннем порядке отказаться от Соглашения в любое время.
                Администрация вправе вносить любые изменения в настоящее Соглашение без направления предварительного
                уведомления
                об этом Пользователю. Новая редакция настоящего Соглашения вступает в силу в момент ее публикации на
                сайте
                (https://dpsking.ru/Oferta), если иное не указано в новой редакции Соглашения.
                Пользователь считается безоговорочно принявшим условия Соглашения в случае, если после их публикации на
                сайте
                (https://dpsking.ru/Oferta) (вступления в силу) он продолжает пользоваться услугами в рамках Сайта.</p>
            <h2>Администрация имеет право прекратить предоставление услуг Пользователю, с односторонним отказом от
                дальнейшего
                сотрудничества, в случаях:</h2>
            <ul>
                <li>Нарушения Пользователем условий настоящего Соглашения;</li>
                <li>Любых действий Пользователя, повлекших за собой сбой в функционировании Сайта, отдельных его частей
                    и размещенного на нам ПО;</li>
                <li>Использования услуг Сайта в незаконных и (или) недобросовестных целях;</li>
                <li>В иных случаях, предусмотренных Соглашением, законодательством Российской Федерации.</li>
            </ul>
            <h2>Отказ от гарантий</h2>
            <p>Стайт предоставляется по принципу «как есть», то есть без гарантий качества и пригодности для каких-либо
                явных
                или подразумеваемых целей конкретного Пользователя (Пользователей).
                Администрация не гарантирует, что Сайт будет работать беспрерывно и безошибочно. Администрация вправе (и
                это
                является существенным условием предоставления услуг) без объяснения причин отказать в доступе всем
                Пользователям
                или любому из них на время или навсегда, удалить любую информацию или любой контент.

                Администрация не гарантирует и не несет ответственность за точность, актуальность, безвредность и
                достоверность
                информации, размещенной на сторонних интернет-ресурсах, ссылки на которые размещаются в рамках Сайта.
                При
                переходе по указанным ссылкам Пользователь осознает, что действует исключительно на свой страх и риск.

                Пользователь подтверждает, что он использует услуги Сайта и ПО на свой страх и риск и понимает, что
                Администрация
                не отвечает за сторонние интернет-ресурсы, программное обеспечение, которые связаны или могут быть
                связаны с
                Сайтом в данный момент или в будущем, включая расположенное прямо на нем.

                Администрация не гарантирует, что любая информация, размещенная в рамках Сайта, будет отвечать
                критериям достоверности, точности и актуальности.</p>
            <h2>Список доступных услуг</h2>
            <ul>
                <li>Покупка ключа активации ПО на один месяц на один спек</li>
                <li>Покупка ключа активации ПО на один месяц на три но не более пяти спеков со скидкой</li>
                <li>Покупка ключа активации ПО на все спеки на один месяц со скидкой</li>
            </ul>
            <h2>Политика возвратов</h2>
            <p>В случае технического сбоя на сервисе, который повлёк за собой некорректное или неполное оказание услуг,
                возможен
                возврат средств. Чтобы решить проблему, на почту dpskingCloud@gmail.com, пришлите следующую информацию:</p>
            <ul>
                <li>1. Подробное и полное описание ситуации на Русском языке</li>
                <li>2. Указать ключ активации</li>
                <li>3. Краткое видео с проблемным вопросом, чтобы мы могли оказать незамедлительную помощь</li>
            </ul>
            <p>Срок рассмотрения 7 рабочих дней.</p>
            <p><strong>Обращаем ваше внимание, что возврат средств за использованный (активированный) ключ не
                осуществляется по
                причине утраты им функционала, и невозможности его повторной активировации. Будьте внимательны.</strong>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "Offer"
}
</script>
<style>
.dpskingOfer {
    background-color: var(--main-bg-color);
}

.dpskingOferContainer {
    min-width: 300px;
    max-width: 1080px;
    margin: 0 auto;
    padding: 50px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.dpskingOferContainer > h2 {
    text-align: center;
    color: var(--orange-color);
    font-family: var(--title-font);
    font-size: 30px;
}

.dpskingOferContainer > h2:nth-child(n+2) {
    margin-top: 20px;
}

.dpskingOferContainer > p, .dpskingOferContainer > ul {
    margin-top: 20px;
    color: var(--blue-light-color);
    font-size: 14px;
    font-family: var(--regular-font);
}

.dpskingOferContainer > ul > li {
    list-style-type: circle;
    list-style-position: outside;
    display: list-item;
    text-align: justify;
}
.dpskingOferContainer > ul > li::marker{
    color: var(--orange-dark-color);
}

.dpskingOferContainer > p {
    text-align: justify;
}

</style>