<template>
    <footer class="footer">
        <div class="container">
            <img class="footer__logo" src="@/assets/images/New_Images/Logo.png" alt="">
            <div class="footer__content">
                <div class="chapter">
                    <h4 class="chapter--title">Обзор</h4>
                    <ul class="">
                        <li class=""><router-link :to="{name: 'Products'}">О нас</router-link></li>
                        <li class=""><router-link :to="{name: 'Contacts'}">Контакты</router-link></li>
                        <li class=""><router-link :to="{name: 'Offer'}">Условия</router-link></li>
                        <li class=""><router-link :to="{name: 'Politic'}">Политика</router-link></li>
                    </ul>
                </div>
                <div class="chapter">
                    <h4 class="chapter--title">Сообщество</h4>
                    <ul class="">
                        <li class=""><a href="https://discord.gg/RmWep3vAB4">Поддержка</a></li>
                        <li class=""><a href="https://discord.gg/RmWep3vAB4">Справка</a></li>

                    </ul>
                </div>
                <div class="chapter">
                    <h4 class="chapter--title">Полезное</h4>
                    <ul class="">
                        <li class=""><router-link :to="{name: 'Instruction'}">Инструкция</router-link></li>
                        <li class=""><a href="https://base.opiums.eu/">WoW Head 3.3.5a</a></li>
                        <li class=""><a href="https://wow-armory.ru/guides/wotlk/">Гайды классов</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="copyright">
            <div>Copyright 2024 DPS King Inc.</div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "Footer"
}
</script>

<style lang="scss">
.footer {
    background-color: #132a28;

    .container {
        display: flex;
        flex-direction: column;
    }

    &__logo {
        width: 130px;
        margin: auto;
    }

    .footer__content {
        max-width: 650px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin-top: 10px;
        margin-bottom: 30px;
        align-self: center;
        flex: 1;
        column-gap: 70px;
        font-family: var(--regular-font);
        color: var(--white-color);

        .chapter {
            .chapter--title {
                font-weight: bold;
                font-size: 12px;
                text-transform: uppercase;
                letter-spacing: 1px;
            }

            ul {
                margin-top: 10px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                font-size: 12px;
            }

            a {
                color: var(--blue-color);
                transition: all .2s ease-in-out;

                &:hover {
                    color: var(--orange-color);
                }
            }
        }
    }

    .copyright {
        padding: 15px 0 15px 0;
        text-align: center;
        background-color: var(--main-bg-color);
        color: var(--white-color);
        font-family: var(--regular-font);
        font-size: 11px;

    }
    
    @media (max-width: 430px) {
        .footer__content{
            column-gap: 20px;
        }
    }
}
</style>