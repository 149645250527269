<template>
    <div class="mc">
        <HeaderMenu/>
        <section class="products">
            <div class="container">
                <div class="products--container"></div>
                <h1 class="products--title"><span>Наши</span> предложения</h1>
                <div class="product--content">
                    <img src="@/assets/images/New_Images/productsPage/wow.png">
                    <div class="product--concrete">
                        <p>У нас вы можете приобрести профессиональные автоматизаторы ротаций персонажей для различных
                            классов</p>
                        <p>Наш ассистент поддерживает 29 различных ротаций и их более детальную настройку под свои
                            нужды, исходя из конкретно ваших потребностей</p>
                        <p>Продукт взаимодействует с игрой только посредством загрузки пользовательского аддона и
                            эмуляции действий реального пользователя, никакого внедрения в игру</p>
                        <p>Крайне продуманный механизм обфускации кода аддона, который установится в папку с вашими
                            расширениями, надежно спрячет вас от глаз античита</p>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
    </div>
</template>

<script>
import HeaderMenu from "@/components/HeaderMenu";
import Footer from "@/components/Footer";

export default {
    components: {Footer, HeaderMenu}
}
</script>

<style lang="scss">

.products {
    min-height: 800px;
    background-color: var(--main-bg-color);
    flex: 1;

    .products--container {
        margin-top: 90px;
        height: 500px;
        background-image: linear-gradient(130deg, rgba(1, 11, 25, 0.95) 5%, rgba(0, 0, 0, 0) 50%), linear-gradient(75deg, rgba(1, 11, 25, 0.95) 5%, rgba(0, 0, 0, 0) 50%), url("~@/assets/images/New_Images/productsPage/headerlich.jpg");
        background-repeat: no-repeat;
        background-size: 1300px;
        background-position: center top;
        position: relative;
    }

    .products--title {
        font-family: var(--title-font);
        font-size: 48px;
        color: var(--blue-color);
        align-self: end;
        margin-top: -30px;
        position: relative;

        span {
            color: orange;
        }
    }

    .product--content {
        margin: 40px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .product--concrete {
            align-self: end;

            p {
                padding: 30px 0 30px 0;
                border-bottom: 1px solid var(--blue-light-color);
                max-width: 600px;
                font-family: var(--regular-font);
                font-size: 16px;
                color: var(--white-color);
                transition: all 0.3s ease-out;
                text-align: end;

                &:hover {
                    color: var(--orange-color)
                }
            }
        }

        img {
            //object-fit: scale-down;
            flex: 1;
        }

    }

    @media (max-width: 1330px) {
        .product--content {
            img {
                object-fit: contain;
                flex-shrink: 1;
            }

            .product--concrete p {
                font-size: 12px;
            }
        }
    }
    @media (max-width: 900px) {
        .products--title {
            font-size: 30px;
            margin-top: -20px;
        }
        .product--content {
            position: relative;
            z-index: 2;
            width: 100%;
            margin: 0 auto;
            align-items: center;
            justify-content: center;

            img {
                position: absolute;
                z-index: 1;
                opacity: 0.2;
                width: 400px;
            }

            .product--concrete {
                z-index: 2;
            }

            .product--concrete p {
                padding: 20px 20px 20px 20px;
                text-align: center;
            }
        }
    }
}

</style>