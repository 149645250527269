<template>
    <div class="mc">
        <HeaderMenu/>
        <div class="contactSection">
            <div class="container">
                <h1 class="contact--title">Контакты</h1>
                <div class="contact--content">
                    <div class="discord">
                        <img src="@/assets/images/New_Images/contactsPage/discord.png" alt="">
                        <a class="link" href="https://discord.gg/RmWep3vAB4" @mouseenter="mouseenter" @mouseleave="mouseleave">Discord</a>
                    </div>
                    <div class="gmail">
                        <img src="@/assets/images/New_Images/contactsPage/email.png" alt="">
                        <a class="link" href="mailto:JoCuCloud@gmail.com" @mouseenter="mouseenter" @mouseleave="mouseleave">JoCuCloud@gmail.com</a>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>

    </div>

</template>

<script>

import HeaderMenu from "@/components/HeaderMenu";
import Footer from "@/components/Footer";
export default {
    name: "Contacts",
    components: {Footer, HeaderMenu},
    methods: {
        mouseenter(event) {
           event['relatedTarget'].firstChild.classList.add('orange-link')
        },
        mouseleave(event){
            event['relatedTarget'].firstChild.classList.remove('orange-link')
        }
    }
}
</script>

<style lang="scss">
.contactSection{
    background-image: url("~@/assets/images/New_Images/contactsPage/lich.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top right;
    flex: 1;
    @media (max-width: 1920px) {
        background-position: top right 20%;
    }
    .contact--title{
        margin-top: 130px;
        font-family: var(--title-font);
        font-size: 48px;
        color: var(--blue-color);
        position: relative;
        z-index: 0;
        width: fit-content;

        &::before{
            content: '';
            position: absolute;
            top: 0;
            right: -10%;
            height: 100%;
            width: 1000%;
            background-color: var(--main-bg-color);
            z-index: -1;
        }
    }
    .contact--content{
        min-height: 600px;
        .discord, .gmail{
            margin-top: 70px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            img{
                transition: all 0.3s ease-out;
            }
        }
        .link{
            text-decoration: none;
            font-family: var(--regular-font);
            font-weight: bolder;
            font-size: 20px;
            color: var(--main-bg-color);
            transition: all 0.3s ease-out;
            vertical-align: center;
            margin-left: 10px;
            &:hover{
                color: var(--orange-color);
                transform: translateX(10px);
            }
        }

        .orange-link{
            filter: invert(69%) sepia(37%) saturate(6055%) hue-rotate(9deg) brightness(96%) contrast(99%);
        }
    }
    @media (max-width: 900px) {
        background-position: top right 40%;
        .contact--content{
            min-height: 400px;
        }
    }
    @media (max-width: 670px) {
        .contact--title{
            margin-top: 100px;
            font-size: 30px;
        }
        .contact--content{
            min-height: 290px;
            .link{
                font-size: 18px;
                font-weight: bolder;
            }
        }
        .contact--content .discord, .contact--content .gmail{
            margin-top: 60px;
            filter: invert(74%) sepia(99%) saturate(179%) hue-rotate(130deg) brightness(96%) contrast(100%);
        }

    }

}

</style>