<template>
    <section class="product">
        <div class="container">
            <h2 class="product--title">Наш<span> продукт</span></h2>
            <div class="product--content">
                <div class="product--content__text">
                    <div class="text_1"><span>Новые возможности</span></div>
                    <div class="text_2"><span><span class="DPSKing">DPS</span> <span class="DPSKing">King</span> - это многофункциональный программный ассистент, который поможет вам высвободить весь потенциал вашего персонажа. Он умеет хилить и наносить урон, очень много урона... Особенностью программы является то, что вы самостоятельно управляете своим персонажем, а вот способности за вас использует алгоритм.  Продукт имеет большое количество тонких настроек и ограничен лишь вашей фантазией.</span>
                    </div>
                    <div class="text_3"><span><span class="DPSKing">DPS</span> <span class="DPSKing">King</span> не является ботом, не встраивается в код оригинальной игры. Он взимодействует с игрой посредством уникально сгенерированного аддона, который будет установлен в папку с игрой. Он ничем не будет отличаться от любого другого, мы позаботились о вашей безопастности, можете быть спокойны.</span>
                    </div>
                    <div class="text_4"><span>Наша команда очень трепетно относится к вопросу сохранности ваших данных, поэтому мы никогда не собираем игровые и персональные данные наших пользователей, мы это гарантируем в отличии от наших конкурентов.</span>
                    </div>
                    <div class="text_5"><span>Повышенная безопасность</span></div>
                </div>
                <img class="product--content__img" src="@/assets/images/New_Images/HorseRider.png" loading="lazy">
            </div>
        </div>
        <img class="frostmorn" src="@/assets/images/New_Images/Frostmorn.png">
    </section>
</template>

<script>
export default {
    name: "Product"
}
</script>

<style lang="scss">
.product {
    background: var(--main-bg-color);
    min-height: 800px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    @media (max-width: 600px) {
        min-height: 620px;
    }
    .product--title {
        margin-top: 90px;
        font-family: var(--title-font);
        font-size: 48px;
        color: var(--blue-color);
        text-align: center;
        position: relative;
        z-index: 2;

        span {
            color: var(--main-bg-color);
            position: relative;
            z-index: 1;

            &::before {
                position: absolute;
                content: '';
                height: 67px;
                width: 3000px;
                left: 10px;
                top: 50%;
                background-color: var(--orange-color);
                z-index: -1;
                transform: translateY(-50%);
            }
        }
        
        @media (max-width: 600px) {
            margin-top: 45px;
            font-size: 30px;
            span::before{
                height: 100%
            }
        }
    }

    .product--content {
        margin-top: 33px;
        position: relative;
        z-index: 1;

        @media (max-width: 795px) {
            position: unset;
        }

        &__text {
            color: #f4fcfd;
            font-family: var(--regular-font);
            font-size: 12px;
            padding: 0 0 40px 40px;
            position: relative;
            max-width: 485px;
            display: flex;
            flex-direction: column;
            line-height: 1.2;
            text-align: left;

            .text_1, .text_5 {
                text-transform: uppercase;
                font-weight: bold;
                font-size: 24px;
                position: absolute;
            }

            .text_1 {
                transform: rotate(-90deg) scaleX(129%) translateX(-100%);
                transform-origin: left top;
                left: 0px;
                top: 0px;
                color: var(--orange-color);
            }

            .text_5 {
                bottom: 0px;
                left: 4.5px;
                color: var(--blue-color);
                transform: scaleX(110%);
                transform-origin: left;
            }
            @media (max-width: 530px) {
                .text_1, .text_5{
                    font-size: 16px;
                }
            }

            .text_2 {
                max-width: 272px;
                align-self: flex-start;
            }

            .text_3 {
                margin-top: 20px;
                max-width: 226px;
                align-self: flex-end;
            }

            .text_4 {
                margin-top: 20px;
                max-width: 262px;
                align-self: flex-start;
            }

            .DPSKing {
                font-weight: bold;
                color: var(--blue-light-color);
                display: inline-block;

            }

            .DPSKing::first-letter {
                color: var(--orange-color);
            }
        }

        &__img {
            position: absolute;
            right: -100px;
            top: -30px;
            z-index: -1;
            transition: opacity .4s ease;
            @media (max-width: 1150px) {
                opacity: 0.6;
            }
            @media (max-width: 795px) {
                opacity: 0.3;
                inset: 0;
                height: 130%;
                object-fit: none;
            }
        }
    }

    .frostmorn {
        position: absolute;
        top: 360px;
        z-index: -1;

        @media (min-width: 2050px){
            display: none;
        }
        @media (max-width: 1784px) {
            top: 220px;
        }
        @media (max-width: 1050px) {
            display: none;
        }
    }
}
</style>