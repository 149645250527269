<template>
    <div class="navigation">
        <div :class="`slide--${item}`" v-for="(item, index) in 6" :key="index" @click="goto(index)"></div>
    </div>
</template>

<script>

import debounce from 'lodash/debounce';

export default {
    name: "NavigationPanel",
    data() {
        return {
            nameSections: ["header", "product", "advantages", "unstoppable", "tariff--sec", "shop"]
        }
    },
    methods: {
        goto(index) {
            window.scrollTo({
                top: document.querySelector('.' + this.nameSections[index]).offsetTop - 50,
                behavior: "smooth"
            })
            this.$el.querySelector(".active").classList.remove("active")
            this.$el.querySelector(".slide--" + (++index)).classList.add("active")
        },
        handleScroll() {
            let winScroll = window.scrollY;
            for (let i = 0; i < this.nameSections.length; i++) {
                if (winScroll <= document.querySelector('.' + this.nameSections[i]).offsetTop
                        + document.querySelector('.' + this.nameSections[i]).clientHeight * 0.5) {
                    let active = this.$el.querySelector(".active")
                    if (active !== null && active !== undefined) {
                        active.classList.remove('active')
                    }
                    this.$el.querySelector(".slide--" + (++i)).classList.add("active")
                    break
                }
                if (winScroll >= document.querySelector(".unstoppable").offsetTop
                        + document.querySelector('.unstoppable').scrollHeight*0.4) {
                    for (let i = 1; i <= 6; i++) {
                        this.$el.querySelector(".slide--" + i).classList.add("orange")
                    }
                }
                else{
                    for (let i = 1; i <= 6; i++) {
                        this.$el.querySelector(".slide--" + i).classList.remove("orange")
                    }
                }
            }

        },
    },
    mounted() {
        this.handleDebouncedScroll = debounce(this.handleScroll, 5);
        window.addEventListener('scroll', this.handleDebouncedScroll);
        this.handleScroll();
    },
    beforeDestroy() {
        // I switched the example from `destroyed` to `beforeDestroy`
        // to exercise your mind a bit. This lifecycle method works too.
        window.removeEventListener('scroll', this.handleDebouncedScroll);
    }
}
</script>

<style lang="scss">
.navigation {
    position: fixed;
    display: flex;
    flex-direction: column;
    gap: 20px;
    z-index: 1;
    justify-content: center;
    align-items: center;
    right: calc((100vw - 1330px) / 2 - 5px);
    top: 50%;
    transform: translateY(-50%);
    animation: openHero 1s 2s ease forwards;
    opacity: 0;

    div {
        border-radius: 50%;
        width: 9px;
        height: 9px;
        background-color: var(--blue-color);
        transition: all 0.3s ease;
        cursor: pointer;
        opacity: 25%;

        &:hover {
            opacity: 1;
        }
    }

    .active {
        transform: scale(1.5);
        opacity: 1;
    }

    .orange {
        background-color: var(--orange-color);
    }

    @media (max-width: 1350px) {
        right: 2%
    }
}
@keyframes openHero {
    from{
        opacity: 0;
        transform: translate(50px, -50%);
    }
    to{
        opacity: 1;
        transform: translate(0, -50%);
    }

}

</style>